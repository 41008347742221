import React, { useState, useEffect } from 'react'
import { useMedia } from 'react-use'
import dayjs from 'dayjs'
import LocalLoader from '../LocalLoader'
import utc from 'dayjs/plugin/utc'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'

import { CustomLink } from '../Link'
import { Divider } from '..'
import { withRouter } from 'react-router-dom'
import { formattedNum } from '../../utils'
import { TYPE } from '../../Theme'
import DoubleTokenLogo from '../DoubleLogo'
import { RowFixed } from '../Row'
import { FilledPersonIcon } from '@axieinfinity/sm-design-system/icons/FilledPerson'
import { Pagination } from '@axieinfinity/sm-design-system'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.primary1};
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 20px 1.5fr 40px 1fr 1fr;
  grid-template-areas: 'number name pairLogo pair value';
  /* padding: 0 4px; */

  > * {
    /* justify-content: flex-end; */
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 10px 1.5fr 1fr 1fr;
    grid-template-areas: 'number name  pair value';
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'name pair value';
  }
`

const ListWrapper = styled.div``

const DataText = styled(Flex)`
  align-items: center;
  text-align: left;
  color: ${({ theme }) => theme.text1};
  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
`

function LPList({ lps, disbaleLinks, maxItems = 10 }) {
  const below600 = useMedia('(max-width: 600px)')
  const below800 = useMedia('(max-width: 800px)')
  const below1280 = useMedia('(max-width: 1280px)')
  const below1080 = useMedia('(max-width: 1080px)')
  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const ITEMS_PER_PAGE = maxItems

  // useEffect(() => {
  //   setMaxPage(1) // edit this to do modular
  //   setPage(1)
  // }, [lps])

  useEffect(() => {
    if (lps) {
      let extraPages = 1
      if (lps.length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(lps.length / ITEMS_PER_PAGE) + extraPages)
    }
  }, [ITEMS_PER_PAGE, lps])

  const ListItem = ({ lp, index }) => {
    return (
      <DashGrid style={{ height: '63px', padding: '0 16px' }} disbaleLinks={disbaleLinks} focus={true}>
        {!below600 && (
          <DataText area="number" fontWeight="400">
            {index}
          </DataText>
        )}
        <DataText area="name" fontWeight="400">
          {!below1080 && (
            <div
              className="min-w-32 h-32 rounded bg-basic-1 flex justify-center items-center text-basic-7"
              style={{ minWidth: 32 }}
            >
              <FilledPersonIcon size={20} />
            </div>
          )}
          <CustomLink
            fontWeight={400}
            style={{ whiteSpace: 'nowrap', marginLeft: below1080 ? 0 : 12 }}
            to={'/account/' + lp.user.id}
          >
            <span className="font-normal">
              {below800
                ? lp.user.id.slice(0, 6) + '...' + lp.user.id.slice(38, 42)
                : below1280
                ? lp.user.id.slice(0, 20) + '...' + lp.user.id.slice(38, 42)
                : lp.user.id}
            </span>
          </CustomLink>
        </DataText>

        {/* {!below1080 && (
          <DataText area="type" justifyContent="flex-end">
            {lp.type}
          </DataText>
        )} */}
        {!below1080 && (
          <DataText>
            <RowFixed area="pairLogo">
              <DoubleTokenLogo a0={lp.token0} a1={lp.token1} size={24} margin={true} iconSize={16} />
            </RowFixed>
          </DataText>
        )}
        <DataText justifyContent={below800 ? 'flex-end' : 'flex-start'}>
          <CustomLink fontWeight={400} color="var(--color-basic-9)" area="pair" to={'/pair/' + lp.pairAddress}>
            <RowFixed>{lp.pairName}</RowFixed>
          </CustomLink>
        </DataText>
        <DataText area="value" justifyContent={below800 ? 'flex-end' : 'flex-start'}>
          {formattedNum(lp.usd, true)}
        </DataText>
      </DashGrid>
    )
  }

  const lpList =
    lps &&
    lps.slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE).map((lp, index) => {
      return (
        <div key={index}>
          <ListItem key={index} index={(page - 1) * maxItems + index + 1} lp={lp} />
          <Divider />
        </div>
      )
    })

  return (
    <ListWrapper>
      <div className={`mb-16 w-full whitespace-nowrap flex md:justify-end ${!below800 && '-mt-48'}`}>
        <Pagination
          currentPage={page}
          limit={maxItems}
          total={lps.length}
          onChangePage={(page) => {
            setPage(page)
          }}
          hasLastFirst
        />
      </div>
      <DashGrid
        center={true}
        className="bg-basic-1 rounded px-16 py-8"
        alignItems="center"
        disbaleLinks={disbaleLinks}
        style={{ height: 'fit-content' }}
      >
        {!below600 && (
          <Flex alignItems="center" justifyContent="flex-start">
            <TYPE.main
              area="number"
              color="var(--color-basic-7)"
              fontSize="10px"
              lineHeight="16px"
              fontWeight="700"
              className="uppercase"
            />
          </Flex>
        )}
        <Flex alignItems="center" justifyContent="flex-start">
          <TYPE.main
            area="name"
            color="var(--color-basic-7)"
            fontSize="10px"
            lineHeight="16px"
            fontWeight="700"
            style={{ marginLeft: below1080 ? 0 : 44 }}
            className="uppercase"
          >
            Account
          </TYPE.main>
        </Flex>
        {/* {!below1080 && (
          <Flex alignItems="center" justifyContent="flexEnd">
            <TYPE.main area="type">Type</TYPE.main>
          </Flex>
        )} */}
        {!below1080 && <Flex area="pairLogo" />}
        <Flex alignItems="center" justifyContent={below800 ? 'flex-end' : 'flex-start'}>
          <TYPE.main
            area="pair"
            color="var(--color-basic-7)"
            fontSize="10px"
            lineHeight="16px"
            fontWeight="700"
            className="uppercase"
          >
            Pair
          </TYPE.main>
        </Flex>
        <Flex alignItems="center" justifyContent={below800 ? 'flex-end' : 'flexStart'}>
          <TYPE.main
            area="value"
            color="var(--color-basic-7)"
            fontSize="10px"
            lineHeight="16px"
            fontWeight="700"
            className="uppercase"
          >
            Value
          </TYPE.main>
        </Flex>
      </DashGrid>
      {/* <Divider /> */}
      <List p={0}>{!lpList ? <LocalLoader /> : lpList}</List>
      {/* <PageButtons>
        <div onClick={() => setPage(page === 1 ? page : page - 1)}>
          <Arrow faded={page === 1 ? true : false}>←</Arrow>
        </div>
        <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
        <div onClick={() => setPage(page === maxPage ? page : page + 1)}>
          <Arrow faded={page === maxPage ? true : false}>→</Arrow>
        </div>
      </PageButtons> */}
    </ListWrapper>
  )
}

export default withRouter(LPList)
