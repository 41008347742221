import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { formatTime, formattedNum, urls } from '../../utils'
import { useMedia } from 'react-use'
import { useCurrentCurrency } from '../../contexts/Application'
import { RowFixed, RowBetween } from '../Row'

import LocalLoader from '../LocalLoader'
import { Box, Flex, Text } from 'rebass'
import Link from '../Link'
import { Divider, EmptyCard } from '..'
import DropdownSelect from '../DropdownSelect'
import FormattedName from '../FormattedName'
import { TYPE } from '../../Theme'
import { updateNameData } from '../../utils/data'
import { CurrencyExchangeIcon } from '@axieinfinity/sm-design-system/icons/CurrencyExchange'
import { DownloadIcon } from '@axieinfinity/sm-design-system/icons/Download'
import { UploadIcon } from '@axieinfinity/sm-design-system/icons/Upload'
import TokenLogo from '../TokenLogo'
import { Pagination } from '@axieinfinity/sm-design-system'
import emptyImg from '../../assets/not-found.png'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div`
  color: #2f80ed;
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  padding-right: 16px;
  padding-left: 16px;
  grid-template-columns: 100px 1fr 1fr;
  grid-template-areas: 'txn value time';

  > * {
    /* justify-content: flex-end; */
    width: 100%;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  }

  @media screen and (min-width: 500px) {
    > * {
      &:first-child {
        width: 180px;
      }
    }
  }

  @media screen and (min-width: 780px) {
    max-width: 1320px;
    grid-template-columns: 32px 1.2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'actionIcon txn value amountToken amountOther time';

    > * {
      &:first-child {
        width: auto;
      }
      &:nth-child(2) {
        width: 180px;
      }
    }
  }

  @media screen and (min-width: 1080px) {
    max-width: 1320px;
    grid-template-columns: 32px 1.2fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'actionIcon txn value amountToken amountOther account time';
  }
`

const ClickableText = styled(Text)`
  /* color: ${({ theme }) => theme.text1}; */
  text-transform: uppercase;
  font-weight: 700;
  user-select: none;
  font-size: 10px;
  line-height: 16px;

  &:hover {
    cursor: pointer;
    color: var(--color-primary-5);
  }

  /* @media screen and (max-width: 640px) {
    font-size: 10px;
  } */
`

const DataText = styled(Flex)`
  align-items: center;
  text-align: ${({ textAlign = 'left' }) => textAlign};
  /* color: ${({ theme }) => theme.text1}; *

  & > * {
    font-size: 14px;
  }

  /* @media screen and (max-width: 40em) {
    font-size: 0.85rem;
  } */
`

const SortText = styled.button`
  cursor: pointer;
  /* font-weight: ${({ active, theme }) => (active ? 500 : 400)}; */
  font-weight: 600;
  margin-right: 12px !important;
  border: none;
  background-color: ${({ active }) => (active ? 'var(--color-primary-1)' : 'transparent')};
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 16px;
  color: ${({ active, theme }) => (active ? 'var(--color-primary-5)' : 'var(--color-basic-7)')};
  outline: none;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const SORT_FIELD = {
  VALUE: 'amountUSD',
  AMOUNT0: 'token0Amount',
  AMOUNT1: 'token1Amount',
  TIMESTAMP: 'timestamp',
}

const TXN_TYPE = {
  ALL: 'All',
  SWAP: 'Swaps',
  ADD: 'Adds',
  REMOVE: 'Removes',
}

const ITEMS_PER_PAGE = 10

function getTransactionType(event, symbol0, symbol1) {
  const formattedS0 = symbol0?.length > 8 ? symbol0.slice(0, 7) + '...' : symbol0
  const formattedS1 = symbol1?.length > 8 ? symbol1.slice(0, 7) + '...' : symbol1
  switch (event) {
    case TXN_TYPE.ADD:
      return 'Add ' + formattedS0 + ' and ' + formattedS1
    case TXN_TYPE.REMOVE:
      return 'Remove ' + formattedS0 + ' and ' + formattedS1
    case TXN_TYPE.SWAP:
      return 'Swap ' + formattedS0 + ' for ' + formattedS1
    default:
      return ''
  }
}

const iconByAction = (action) => {
  if (!action) {
    return <></>
  }
  return action === 'Swaps' ? (
    <CurrencyExchangeIcon size={20} className="text-basic-7" />
  ) : action === 'Adds' ? (
    <DownloadIcon size={20} className="text-basic-7" />
  ) : (
    <UploadIcon size={20} className="text-basic-7" />
  )
}

// @TODO rework into virtualized list
function TxnList({ transactions, symbol0Override, symbol1Override, color }) {
  // page state
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  // sorting
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.TIMESTAMP)
  const [filteredItems, setFilteredItems] = useState()
  const [txFilter, setTxFilter] = useState(TXN_TYPE.ALL)

  const [currency] = useCurrentCurrency()

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [transactions])

  // parse the txns and format for UI
  useEffect(() => {
    if (transactions && transactions.mints && transactions.burns && transactions.swaps) {
      let newTxns = []
      if (transactions.mints.length > 0) {
        transactions.mints.map((mint) => {
          let newTxn = {}
          newTxn.hash = mint.transaction.id
          newTxn.timestamp = mint.transaction.timestamp
          newTxn.type = TXN_TYPE.ADD
          newTxn.token0Amount = mint.amount0
          newTxn.token1Amount = mint.amount1
          newTxn.account = mint.to
          newTxn.token0Address = mint.pair.token0.id
          newTxn.token1Address = mint.pair.token1.id
          newTxn.token0Symbol = updateNameData(mint.pair).token0.symbol
          newTxn.token1Symbol = updateNameData(mint.pair).token1.symbol
          newTxn.amountUSD = mint.amountUSD
          return newTxns.push(newTxn)
        })
      }
      if (transactions.burns.length > 0) {
        transactions.burns.map((burn) => {
          let newTxn = {}
          newTxn.hash = burn.transaction.id
          newTxn.timestamp = burn.transaction.timestamp
          newTxn.type = TXN_TYPE.REMOVE
          newTxn.token0Amount = burn.amount0
          newTxn.token1Amount = burn.amount1
          newTxn.account = burn.sender
          newTxn.token0Address = burn.pair.token0.id
          newTxn.token1Address = burn.pair.token1.id
          newTxn.token0Symbol = updateNameData(burn.pair).token0.symbol
          newTxn.token1Symbol = updateNameData(burn.pair).token1.symbol
          newTxn.amountUSD = burn.amountUSD
          return newTxns.push(newTxn)
        })
      }
      if (transactions.swaps.length > 0) {
        transactions.swaps.map((swap) => {
          const netToken0 = swap.amount0In - swap.amount0Out
          const netToken1 = swap.amount1In - swap.amount1Out

          let newTxn = {}

          if (netToken0 < 0) {
            newTxn.token0Symbol = updateNameData(swap.pair).token0.symbol
            newTxn.token1Symbol = updateNameData(swap.pair).token1.symbol
            newTxn.token0Address = swap.pair.token0.id
            newTxn.token1Address = swap.pair.token1.id
            newTxn.token0Amount = Math.abs(netToken0)
            newTxn.token1Amount = Math.abs(netToken1)
          } else if (netToken1 < 0) {
            newTxn.token0Symbol = updateNameData(swap.pair).token1.symbol
            newTxn.token1Symbol = updateNameData(swap.pair).token0.symbol
            newTxn.token0Address = swap.pair.token1.id
            newTxn.token1Address = swap.pair.token0.id
            newTxn.token0Amount = Math.abs(netToken1)
            newTxn.token1Amount = Math.abs(netToken0)
          }

          newTxn.hash = swap.transaction.id
          newTxn.timestamp = swap.transaction.timestamp
          newTxn.type = TXN_TYPE.SWAP

          newTxn.amountUSD = swap.amountUSD
          newTxn.account = swap.to
          return newTxns.push(newTxn)
        })
      }

      const filtered = newTxns.filter((item) => {
        if (txFilter !== TXN_TYPE.ALL) {
          return item.type === txFilter
        }
        return true
      })
      setFilteredItems(filtered)
      let extraPages = 1
      if (filtered.length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      if (filtered.length === 0) {
        setMaxPage(1)
      } else {
        setMaxPage(Math.floor(filtered.length / ITEMS_PER_PAGE) + extraPages)
      }
    }
  }, [transactions, txFilter])

  useEffect(() => {
    setPage(1)
  }, [txFilter])

  const filteredList =
    filteredItems &&
    filteredItems
      .sort((a, b) => {
        return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn])
          ? (sortDirection ? -1 : 1) * 1
          : (sortDirection ? -1 : 1) * -1
      })
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)

  const below1080 = useMedia('(max-width: 1080px)')
  const below780 = useMedia('(max-width: 780px)')

  const ListItem = ({ item }) => {
    return (
      <DashGrid style={{ height: '64px' }}>
        {!below780 && (
          <DataText area="actionIcon">
            <div className="h-32 w-32 rounded bg-basic-1 flex items-center justify-center">
              {iconByAction(item.type)}
            </div>
          </DataText>
        )}
        <DataText area="txn" fontWeight="600">
          <Link
            color="var(--color-basic-9)!important; text-decoration: none!important"
            external
            shouldRemoveRelAttr
            href={urls.showTransaction(item.hash)}
          >
            {getTransactionType(item.type, item.token1Symbol, item.token0Symbol)}
          </Link>
        </DataText>
        <DataText
          area="value"
          justifyContent={below780 ? 'flex-end' : 'flex-start'}
          className="text-right md:text-left"
        >
          {currency === 'WETH' ? 'Ξ ' + formattedNum(item.valueETH) : formattedNum(item.amountUSD, true)}
        </DataText>
        {!below780 && (
          <>
            <DataText area="amountOther">
              <TokenLogo address={item.token1Address} size={20} className="mr-4" />{' '}
              {formattedNum(item.token1Amount) + ' '}{' '}
              <FormattedName text={item.token1Symbol} maxCharacters={5} margin={true} />
            </DataText>
            <DataText area="amountToken">
              <TokenLogo address={item.token0Address} size={20} className="mr-4" />{' '}
              {formattedNum(item.token0Amount) + ' '}{' '}
              <FormattedName text={item.token0Symbol} maxCharacters={5} margin={true} />
            </DataText>
          </>
        )}
        {!below1080 && (
          <DataText area="account">
            <Link
              color={color}
              fontWeight="400!important"
              external
              shouldRemoveRelAttr
              href={process.env.REACT_APP_EXPLORER + '/address/' + item.account?.replace('0x', 'ronin:')}
            >
              {item.account && item.account.slice(0, 6) + '...' + item.account.slice(38, 42)}
            </Link>
          </DataText>
        )}
        <DataText
          area="time"
          justifyContent={below780 ? 'flex-end' : 'flex-start'}
          textAlign={below780 ? 'right' : 'left'}
          className="text-right md:text-left"
        >
          {formatTime(item.timestamp)}
        </DataText>
      </DashGrid>
    )
  }

  return (
    <>
      <div className="flex mb-12 items-start md:items-center md:justify-between flex-col md:flex-row">
        {below780 ? (
          <RowBetween area="txn" mb={16}>
            <DropdownSelect options={TXN_TYPE} active={txFilter} setActive={setTxFilter} color={color} />
          </RowBetween>
        ) : (
          <RowFixed area="txn" gap="10px" pl={4}>
            <SortText
              onClick={() => {
                setTxFilter(TXN_TYPE.ALL)
              }}
              active={txFilter === TXN_TYPE.ALL}
            >
              All
            </SortText>
            <SortText
              onClick={() => {
                setTxFilter(TXN_TYPE.SWAP)
              }}
              active={txFilter === TXN_TYPE.SWAP}
            >
              Swaps
            </SortText>
            <SortText
              onClick={() => {
                setTxFilter(TXN_TYPE.ADD)
              }}
              active={txFilter === TXN_TYPE.ADD}
            >
              Adds
            </SortText>
            <SortText
              onClick={() => {
                setTxFilter(TXN_TYPE.REMOVE)
              }}
              active={txFilter === TXN_TYPE.REMOVE}
            >
              Removes
            </SortText>
          </RowFixed>
        )}
        <div className="whitespace-nowrap">
          <Pagination
            limit={10}
            currentPage={page}
            total={filteredItems?.filter((obj) => obj.type === txFilter || txFilter === TXN_TYPE.ALL).length || 0}
            onChangePage={(page) => {
              setPage(page)
            }}
            hasLastFirst
          />
        </div>
      </div>
      <div className="bg-basic-1 rounded">
        <DashGrid center={true} style={{ height: 'fit-content' }} className="py-8 items-center  ">
          {!below780 && <Flex area="actionIcon" />}
          <Flex area="txn">
            <span className="uppercase font-bold text-basic-7 text-10 leading-16">Action</span>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent={below780 ? 'flex-end' : 'flexStart'}
            className="text-right md:text-left"
          >
            <ClickableText
              color={sortedColumn === SORT_FIELD.VALUE ? 'var(--color-primary-5)' : 'var(--color-basic-7)'}
              lineHeight="16px"
              area="value"
              onClick={(e) => {
                setSortedColumn(SORT_FIELD.VALUE)
                setSortDirection(sortedColumn !== SORT_FIELD.VALUE ? true : !sortDirection)
              }}
            >
              Total Value {sortedColumn === SORT_FIELD.VALUE ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
          {!below780 && (
            <Flex alignItems="center">
              <ClickableText
                area="amountToken"
                color={sortedColumn === SORT_FIELD.AMOUNT0 ? 'var(--color-primary-5)' : 'var(--color-basic-7)'}
                lineHeight="16px"
                onClick={() => {
                  setSortedColumn(SORT_FIELD.AMOUNT0)
                  setSortDirection(sortedColumn !== SORT_FIELD.AMOUNT0 ? true : !sortDirection)
                }}
              >
                {symbol0Override ? symbol0Override + ' Amount' : 'Token Amount'}{' '}
                {sortedColumn === SORT_FIELD.AMOUNT0 ? (sortDirection ? '↑' : '↓') : ''}
              </ClickableText>
            </Flex>
          )}
          <>
            {!below780 && (
              <Flex alignItems="center">
                <ClickableText
                  area="amountOther"
                  color={sortedColumn === SORT_FIELD.AMOUNT1 ? 'var(--color-primary-5)' : 'var(--color-basic-7)'}
                  lineHeight="16px"
                  onClick={() => {
                    setSortedColumn(SORT_FIELD.AMOUNT1)
                    setSortDirection(sortedColumn !== SORT_FIELD.AMOUNT1 ? true : !sortDirection)
                  }}
                >
                  {symbol1Override ? symbol1Override + ' Amount' : 'Token Amount'}{' '}
                  {sortedColumn === SORT_FIELD.AMOUNT1 ? (sortDirection ? '↑' : '↓') : ''}
                </ClickableText>
              </Flex>
            )}
            {!below1080 && (
              <Flex alignItems="center">
                <TYPE.body
                  area="account"
                  color="var(--color-basic-7)"
                  fontWeight="700"
                  fontSize={10}
                  lineHeight="16px"
                  className="uppercase"
                >
                  Account
                </TYPE.body>
              </Flex>
            )}
            <Flex alignItems="center" justifyContent={below780 ? 'flex-end' : 'felx-start'}>
              <ClickableText
                area="time"
                color={sortedColumn === SORT_FIELD.TIMESTAMP ? 'var(--color-primary-5)' : 'var(--color-basic-7)'}
                lineHeight="16px"
                onClick={() => {
                  setSortedColumn(SORT_FIELD.TIMESTAMP)
                  setSortDirection(sortedColumn !== SORT_FIELD.TIMESTAMP ? true : !sortDirection)
                }}
              >
                Time {sortedColumn === SORT_FIELD.TIMESTAMP ? (!sortDirection ? '↑' : '↓') : ''}
              </ClickableText>
            </Flex>
          </>
        </DashGrid>
      </div>
      {/* <Divider /> */}
      <List p={0}>
        {!filteredList ? (
          <LocalLoader />
        ) : filteredList.length === 0 ? (
          <EmptyCard>
            <img
              src={emptyImg}
              alt=""
              style={{
                width: 280,
                maxWidth: '80vw',
              }}
              className="mb-8"
            />
            <span className="text-basic-7 text-14 leading-20">No transaction found.</span>
          </EmptyCard>
        ) : (
          filteredList.map((item, index) => {
            return (
              <div key={index}>
                <ListItem key={index} index={index + 1} item={item} />
                <Divider />
              </div>
            )
          })
        )}
      </List>
      {/* <PageButtons>
        <div
          onClick={(e) => {
            setPage(page === 1 ? page : page - 1)
          }}
        >
          <Arrow faded={page === 1 ? true : false}>←</Arrow>
        </div>
        <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
        <div
          onClick={(e) => {
            setPage(page === maxPage ? page : page + 1)
          }}
        >
          <Arrow faded={page === maxPage ? true : false}>→</Arrow>
        </div>
      </PageButtons> */}
    </>
  )
}

export default TxnList
