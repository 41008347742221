import { smProducts } from '@axieinfinity/fe-plugins'
import { Button, Overlay, Menu, MenuItem } from '@axieinfinity/sm-design-system'
import React, { useState, useEffect } from 'react'
import logoImg from '../../assets/ronin-blacktext.svg'
import { MenuRightIcon } from '@axieinfinity/sm-design-system/icons/MenuRight'
import { useHistory } from 'react-router-dom'
import { CrossIcon } from '@axieinfinity/sm-design-system/icons/Cross'
import { FilledBarChartIcon } from '@axieinfinity/sm-design-system/icons/FilledBarChart'
import { FilledGridIcon } from '@axieinfinity/sm-design-system/icons/FilledGrid'
import { CaretDownIcon } from '@axieinfinity/sm-design-system/icons/CaretDown'
import { ReactComponent as TokenIcon } from '../../assets/filled-token.svg'
import { ReactComponent as PairTokenIcon } from '../../assets/filled-coin-pair.svg'
import { FilledPersonIcon } from '@axieinfinity/sm-design-system/icons/FilledPerson'
import { Polling, PollingDot } from '../SideNav/index'
import { CustomLink } from '../Link'
import { useToggle } from 'react-use'
import { disabledFeature } from '../../utils/featureConfigs'

export const MobileNav = ({ selectedItem, onChangeMenu, seconds }) => {
  const [showMenu, toggleShowMenu] = useToggle(false)
  const [openProductsMenu, toggleProductsMenu] = useToggle(false)

  const handleSelectMenu = (e, url) => {
    if (url === 'products') {
      toggleProductsMenu()
      return
    }

    onChangeMenu(url, toggleShowMenu)()
  }

  useEffect(() => {
    if (showMenu) {
      const backdrop = document.getElementsByClassName('header-menu-burger')[0].parentElement
      if (backdrop) {
        backdrop.style.position = 'absolute'
        backdrop.style.right = '0'
        backdrop.style.left = 'auto'
        backdrop.style.transform = 'translateX(0)'
      }
    }
  }, [showMenu])

  return (
    <div className="flex justify-between py-8 bg-basic-1 items-center px-20">
      <CustomLink to="/">
        <img src={logoImg} alt="Ronin Network" className="h-48" />
      </CustomLink>
      <Button leftIcon={MenuRightIcon} minimal onClick={toggleShowMenu} />
      <Overlay isOpen={showMenu} withBackdrop>
        <Menu
          noShadow
          selectedItem={openProductsMenu ? 'products' : selectedItem}
          onSelect={handleSelectMenu}
          className="h-full p-20 pt-24 overflow-auto flex flex-col header-menu-burger rounded-0 h-screen w-full"
        >
          <div className="absolute right-12 top-12">
            <Button leftIcon={CrossIcon} minimal onClick={toggleShowMenu} className="w-min" />
          </div>

          <MenuItem
            icon={FilledBarChartIcon}
            className="menu-item-home mt-28 font-semibold h-32 px-16 mb-12"
            id="home"
            label="Overview"
          />
          <MenuItem
            icon={TokenIcon}
            className="menu-item-home font-semibold h-32 px-16 mb-12"
            id="tokens"
            label="Tokens"
          />
          {!disabledFeature.includes('pair') && (
            <MenuItem
              icon={PairTokenIcon}
              className="menu-item-home font-semibold h-32 px-16 mb-12"
              id="pairs"
              label="Pairs"
            /> 
          )}
          <MenuItem
            icon={FilledPersonIcon}
            className="menu-item-home font-semibold h-32 px-16 mb-12"
            id="accounts"
            label="Accounts"
          />
          <MenuItem
            icon={FilledGridIcon}
            rightElement={<CaretDownIcon size={24} />}
            className="menu-item-home font-semibold h-32 px-16 mb-12"
            id="products"
            label="Products"
          />
          {openProductsMenu &&
            smProducts.map((product) => (
              <a
                href={product.url}
                target="_blank"
                key={product.name}
                className="h-32 pl-52 pl-40 pr-16 mb-12"
                rel="noreferrer"
                style={{
                  textDecoration: 'none',
                  fontWeight: 400,
                  color: 'var(--color-basic-9)',
                }}
              >
                {product.name}
              </a>
            ))}

          <Polling style={{ marginLeft: '.25rem', marginBottom: '.25rem' }}>
            <PollingDot />
            {/* <a href="/" style={{ color: 'white' }}> */}
            Updated {!!seconds ? seconds + 's' : '-'} ago <br />
            {/* </a> */}
          </Polling>
        </Menu>
      </Overlay>
    </div>
  )
}
