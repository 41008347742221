import React, { useState, useRef } from 'react'
import 'feather-icons'
import { withRouter } from 'react-router-dom'
import { Text, Flex } from 'rebass'
import styled from 'styled-components'
import Link from '../components/Link'
import Panel from '../components/Panel'
import TokenLogo from '../components/TokenLogo'
import PairList from '../components/PairList'
import Loader from '../components/LocalLoader'
import { AutoRow, RowBetween, RowFixed } from '../components/Row'
import Column, { AutoColumn } from '../components/Column'
import { ButtonLight, ButtonDark } from '../components/ButtonStyled'
import TxnList from '../components/TxnList'
import TokenChart from '../components/TokenChart'
import { BasicLink } from '../components/Link'
import Search from '../components/Search'
import { formattedNum, formattedPercent, getPoolLink, getSwapLink, localNumber } from '../utils'
import { useTokenData, useTokenTransactions, useTokenPairs } from '../contexts/TokenData'
// import { TYPE, ThemedBackground } from '../Theme'
import { TYPE } from '../Theme'
import { transparentize } from 'polished'
import { useColor } from '../hooks'
import CopyHelper from '../components/Copy'
import { useMedia } from 'react-use'
import { useDataForList } from '../contexts/PairData'
import { useEffect } from 'react'
import Warning from '../components/Warning'
import { usePathDismissed, useSavedTokens } from '../contexts/LocalStorage'
import {
  Hover,
  PageWrapper,
  ContentWrapper,
  StyledIcon,
  BlockedWrapper,
  BlockedMessageWrapper,
  Divider,
} from '../components'
import { PlusCircle, Bookmark, AlertCircle, ChevronRight } from 'react-feather'
import FormattedName from '../components/FormattedName'
import { useListedTokens } from '../contexts/Application'
import HoverText from '../components/HoverText'
import { UNTRACKED_COPY, TOKEN_BLACKLIST, BLOCKED_WARNINGS } from '../constants'
import QuestionHelper from '../components/QuestionHelper'
import Checkbox from '../components/Checkbox'
import { shortenAddress } from '../utils'
import { MagnifierIcon } from '@axieinfinity/sm-design-system/icons/Magnifier'
import { TrendStatus } from '../components/TrendStatus'
import { Button, IntentType, Tooltip } from '@axieinfinity/sm-design-system'
import { PlusIcon } from '@axieinfinity/sm-design-system/icons/Plus'
import { CopyIcon } from '@axieinfinity/sm-design-system/icons/Copy'
import { ExternalLinkIcon } from '@axieinfinity/sm-design-system/icons/ExternalLink'
import { ChevronRightIcon } from '@axieinfinity/sm-design-system/icons/ChevronRight'
import { ViewOnExplorerButton } from '../components/ViewOnExplorerButton'

const DashboardWrapper = styled.div`
  width: 100%;
`
const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 80px 1fr 1fr 1fr;
  grid-template-areas: 'symbol name address button';
  padding: 0 1.125rem;
`

const PanelWrapper = styled.div`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 8px;
  display: inline-grid;
  width: 100%;
  align-items: start;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    gap: 16px;
    > * {
      /* grid-column: 1 / 4; */
    }

    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
`

const TokenDetailsLayout = styled.div`
  display: inline-grid;
  width: 100%;
  grid-template-columns: auto auto auto 1fr;
  column-gap: 30px;
  align-items: start;

  &:last-child {
    align-items: center;
    justify-items: end;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      /* grid-column: 1 / 4; */
      margin-bottom: 1rem;
    }

    &:last-child {
      align-items: start;
      justify-items: start;
    }
  }
`

const WarningIcon = styled(AlertCircle)`
  stroke: ${({ theme }) => theme.text1};
  height: 16px;
  width: 16px;
  opacity: 0.6;
`

const WarningGrouping = styled.div`
  opacity: ${({ disabled }) => disabled && '0.4'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

function TokenPage({ address, history }) {
  const {
    id,
    name,
    symbol,
    priceUSD,
    oneDayVolumeUSD,
    totalLiquidityUSD,
    volumeChangeUSD,
    oneDayVolumeUT,
    volumeChangeUT,
    priceChangeUSD,
    liquidityChangeUSD,
    oneDayTxns,
    txnChange,
  } = useTokenData(address)

  const [showSearch, setShowSearch] = useState(false)

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  // detect color from token
  const backgroundColor = useColor(id, symbol)

  const allPairs = useTokenPairs(address)

  // pairs to show in pair list
  const fetchedPairsList = useDataForList(allPairs)

  // all transactions with this token
  const transactions = useTokenTransactions(address)

  // price
  const price = priceUSD ? formattedNum(priceUSD, true) : ''
  const priceChange = <TrendStatus value={priceChangeUSD || 0} />

  // volume
  const volume = formattedNum(!!oneDayVolumeUSD ? oneDayVolumeUSD : oneDayVolumeUT, true)

  const usingUtVolume = oneDayVolumeUSD === 0 && !!oneDayVolumeUT
  // const volumeChange = formattedPercent(!usingUtVolume ? volumeChangeUSD : volumeChangeUT)
  const volumeChange = <TrendStatus value={!usingUtVolume ? volumeChangeUSD : volumeChangeUT} />

  // liquidity
  const liquidity = formattedNum(totalLiquidityUSD, true)
  // const liquidityChange = formattedPercent(liquidityChangeUSD)
  const liquidityChange = <TrendStatus value={liquidityChangeUSD} />

  // transactions
  // const txnChangeFormatted = formattedPercent(txnChange)
  const txnChangeFormatted = <TrendStatus value={txnChange} />

  const below1080 = useMedia('(max-width: 1080px)')
  const below1280 = useMedia('(max-width: 1280px)')
  const below800 = useMedia('(max-width: 800px)')
  const below600 = useMedia('(max-width: 600px)')
  const below500 = useMedia('(max-width: 500px)')

  // format for long symbol
  const LENGTH = below1080 ? 10 : 16
  const formattedSymbol = symbol?.length > LENGTH ? symbol.slice(0, LENGTH) + '...' : symbol

  const [dismissed, markAsDismissed] = usePathDismissed(history.location.pathname)
  const [savedTokens, addToken] = useSavedTokens()
  const listedTokens = useListedTokens()
  const [showCopied, setShowCopied] = useState(false)
  const refCopy = useRef(null)

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
    return () => {
      if (refCopy.current) {
        clearTimeout(refCopy.current)
      }
    }
  }, [])

  const [useTracked, setUseTracked] = useState(true)

  if (TOKEN_BLACKLIST.includes(address)) {
    return (
      <BlockedWrapper>
        <BlockedMessageWrapper>
          <AutoColumn gap="1rem" justify="center">
            <TYPE.light style={{ textAlign: 'center' }}>
              {BLOCKED_WARNINGS[address] ?? `This token is not supported.`}
            </TYPE.light>
            <Link
              external={true}
              shouldRemoveRelAttr
              href={process.env.REACT_APP_EXPLORER + '/address/' + address}
            >{`More about ${shortenAddress(address)}`}</Link>
          </AutoColumn>
        </BlockedMessageWrapper>
      </BlockedWrapper>
    )
  }

  return (
    <PageWrapper>
      {/* <ThemedBackground backgroundColor={transparentize(0.6, backgroundColor)} /> */}
      {/* <Warning
        type={'token'}
        show={!dismissed && listedTokens && !listedTokens.includes(address)}
        setShow={markAsDismissed}
        address={address}
      /> */}
      <ContentWrapper>
        <RowBetween style={{ flexWrap: 'wrap', alingItems: 'start' }}>
          <AutoRow align="center" style={{ width: 'fit-content' }}>
            <TYPE.body>
              <BasicLink to="/tokens">
                <span className="text-basic-7 font-semibold text-14 leading-20">{'Tokens '}</span>
              </BasicLink>
            </TYPE.body>
            <ChevronRightIcon size={20} className="mx-8 text-basic-4" />
            <span className="text-14 leading-20 font-semibold mr-4 whitespace-nowrap overflow-ellipsis overflow-hidden">
              {name}
            </span>
            <Link
              style={{ width: 'fit-content' }}
              color={backgroundColor}
              fontSize={14}
              external
              shouldRemoveRelAttr
              href={process.env.REACT_APP_EXPLORER + '/token/' + address}
            >
              <Text style={{ marginLeft: '.15rem' }} fontSize={below500 ? '10px' : '14px'} fontWeight={400}>
                ({address.slice(0, 6) + '...' + address.slice(38, 42)})
              </Text>
            </Link>
          </AutoRow>
          {!below600 && showSearch && <Search small={true} onClickOutsideSearch={() => setShowSearch(false)} />}
          {!below600 && !showSearch && (
            <MagnifierIcon className="text-basic-7 cursor-pointer" size={32} onClick={() => setShowSearch(true)} />
          )}
        </RowBetween>
        {/* <WarningGrouping disabled={!dismissed && listedTokens && !listedTokens.includes(address)}> */}
        <DashboardWrapper style={{ marginTop: below1080 ? '0' : '1rem' }}>
          <RowBetween
            style={{
              flexWrap: 'wrap',
              marginBottom: '1.25rem',
              alignItems: 'flex-start',
            }}
          >
            <RowFixed style={{ flexWrap: 'wrap' }}>
              <RowFixed style={{ alignItems: 'center' }}>
                <div
                  className="flex justify-center items-center rounded-12 bg-basic-1 mr-20"
                  style={{ height: below800 ? 32 : 64, width: below800 ? 32 : 64 }}
                >
                  <TokenLogo address={address} size={below800 ? 20 : 40} style={{ alignSelf: 'center' }} />
                </div>
                <div>
                  <TYPE.main
                    fontSize={below1080 ? '16px' : '24px'}
                    fontWeight={600}
                    lineHeight={below1080 ? '24px' : '36px'}
                  >
                    <RowFixed gap="6px">
                      <FormattedName text={name ? name + ' ' : ''} maxCharacters={50} style={{ marginRight: '6px' }} />{' '}
                      {formattedSymbol ? `(${formattedSymbol})` : ''}
                    </RowFixed>
                  </TYPE.main>{' '}
                  {/* {!below1080 && ( */}
                  <RowFixed>
                    <TYPE.main fontSize={'16px'} fontWeight={400} lineHeight="24px" style={{ marginRight: '0.5rem' }}>
                      {price}
                    </TYPE.main>
                    {priceChange}
                    <div className="ml-12 items-center hidden md:flex">
                      <div className="h-12 border border-basic-3 mr-12 border-r-0" />
                      <ViewOnExplorerButton address={`${process.env.REACT_APP_EXPLORER}/token/${address}`} />
                    </div>
                  </RowFixed>
                  {/* )} */}
                </div>
              </RowFixed>
            </RowFixed>
            <div className="md:hidden mt-16">
              <Link href={`${process.env.REACT_APP_EXPLORER}/token/${address}`} target="_blank">
                <Button rightIcon={ExternalLinkIcon}>View on Ronin</Button>
              </Link>
            </div>
            <span>
              <RowFixed ml={below1080 ? '0' : '2.5rem'} mt={below1080 ? '1rem' : '0'}>
                {/* {!!!savedTokens[address] && !below800 ? (
                  <Hover onClick={() => addToken(address, symbol)}>
                    <StyledIcon>
                      <PlusCircle style={{ marginRight: '0.5rem' }} />
                    </StyledIcon>
                  </Hover>
                ) : !below1080 ? (
                  <StyledIcon>
                    <Bookmark style={{ marginRight: '0.5rem', opacity: 0.4 }} />
                  </StyledIcon>
                ) : (
                  <></>
                )} */}
                <Link href={getPoolLink(address)} target="_blank" className="hover:no-underline">
                  {/* <ButtonLight color={backgroundColor}>+ Add Liquidity</ButtonLight> */}
                  <Button color={backgroundColor} leftIcon={PlusIcon}>
                    {' '}
                    Add Liquidity
                  </Button>
                </Link>
                <Link
                  href={
                    address.toLowerCase() === process.env.REACT_APP_WRON_ADDRESS.toLowerCase()
                      ? getSwapLink('RON')
                      : getSwapLink(address)
                  }
                  target="_blank"
                >
                  {/* <ButtonDark ml={'1.25rem'} mr={below1080 && '.5rem'} color={backgroundColor}>
                    Trade
                  </ButtonDark> */}
                  <Button className="ml-20" intent={IntentType.Primary}>
                    Trade
                  </Button>
                </Link>
              </RowFixed>
            </span>
          </RowBetween>

          <>
            {!below1080 && (
              <RowFixed>
                <TYPE.main fontSize="20px" lineHeight="28px" fontWeight={600} mr="6px">
                  Token Stats
                </TYPE.main>
                {usingUtVolume && (
                  <HoverText text={UNTRACKED_COPY}>
                    <WarningIcon />
                  </HoverText>
                )}
              </RowFixed>
            )}
            <PanelWrapper style={{ marginTop: below1080 ? '0' : '0.5rem', padding: 0 }}>
              {/* {below1080 && price && (
                <Panel hasBorder>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Price</TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      {' '}
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                        {price}
                      </TYPE.main>
                      <TYPE.main>{priceChange}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
              )} */}
              <Panel hasBorder>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main
                      fontWeight={700}
                      fontSize={10}
                      lineHeight="16px"
                      className="uppercase"
                      color="var(--color-basic-7)"
                    >
                      Total Liquidity
                    </TYPE.main>
                    <div />
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.main fontWeight={600} fontSize={24} lineHeight="34px">
                      {liquidity}
                    </TYPE.main>
                    <TYPE.main>{liquidityChange}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </Panel>
              <Panel hasBorder>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main
                      fontWeight={700}
                      fontSize={10}
                      lineHeight="16px"
                      className="uppercase"
                      color="var(--color-basic-7)"
                    >
                      Volume (24hrs)
                    </TYPE.main>
                    <div />
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.main fontWeight={600} fontSize={24} lineHeight="34px">
                      {volume}
                    </TYPE.main>
                    <TYPE.main>{volumeChange}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </Panel>

              <Panel hasBorder>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main
                      fontWeight={700}
                      fontSize={10}
                      lineHeight="16px"
                      className="uppercase"
                      color="var(--color-basic-7)"
                    >
                      Transactions (24hrs)
                    </TYPE.main>
                    <div />
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.main fontWeight={600} fontSize={24} lineHeight="34px">
                      {oneDayTxns ? localNumber(oneDayTxns) : 0}
                    </TYPE.main>
                    <TYPE.main>{txnChangeFormatted}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </Panel>
              <Panel
                hasBorder
                style={{
                  gridColumn: below1080 ? '1' : '2/4',
                  gridRow: below1080 ? '' : '1/4',
                }}
              >
                <TokenChart address={address} color={backgroundColor} base={priceUSD} />
              </Panel>
            </PanelWrapper>
          </>

          <RowBetween style={{ marginTop: below800 ? '28px' : '48px' }}>
            <TYPE.main fontSize={below800 ? 16 : 20} fontWeight={600} lineHeight={below800 ? '24px' : '28px'}>
              Top Pairs
            </TYPE.main>
            {/* <AutoRow gap="4px" style={{ width: 'fit-content' }}>
              <Checkbox
                checked={useTracked}
                setChecked={() => setUseTracked(!useTracked)}
                text={'Hide untracked pairs'}
              />
              <QuestionHelper text="USD amounts may be inaccurate in low liquiidty pairs or pairs without ETH or stablecoins." />
            </AutoRow> */}
          </RowBetween>
          <Panel
            rounded
            style={{
              marginTop: '1rem',
              padding: 0,
            }}
          >
            {address && fetchedPairsList ? (
              <PairList color={backgroundColor} address={address} pairs={fetchedPairsList} useTracked={useTracked} />
            ) : (
              <Loader />
            )}
          </Panel>
          <RowBetween style={{ marginTop: below800 ? '28px' : '48px', marginBottom: 16 }}>
            <TYPE.main fontSize={below800 ? 16 : 20} fontWeight={600} lineHeight={below800 ? '24px' : '28px'}>
              Transactions
            </TYPE.main>{' '}
            <div />
          </RowBetween>
          <Panel rounded p={0}>
            {transactions ? <TxnList color={backgroundColor} transactions={transactions} /> : <Loader />}
          </Panel>
          {/* <>
            <RowBetween style={{ marginTop: '3rem' }}>
              <TYPE.main fontSize={20} fontWeight={600} lineHeight="28px">
                Token Information
              </TYPE.main>{' '}
            </RowBetween>
            {below800 && (
              <Panel
                rounded
                style={{
                  marginTop: '1rem',
                  padding: 0,
                }}
              >
                <TokenDetailsLayout>
                  <Column>
                    <TYPE.main>Symbol</TYPE.main>
                    <Text style={{ marginTop: '.5rem' }} fontSize={24} fontWeight="500">
                      <FormattedName text={symbol} maxCharacters={12} />
                    </Text>
                  </Column>
                  <Column>
                    <TYPE.main>Name</TYPE.main>
                    <TYPE.main style={{ marginTop: '.5rem' }} fontSize={24} fontWeight="500">
                      <FormattedName text={name} maxCharacters={16} />
                    </TYPE.main>
                  </Column>
                  <Column>
                    <TYPE.main>Address</TYPE.main>
                    <AutoRow align="flex-end">
                      <TYPE.main style={{ marginTop: '.5rem' }} fontSize={24} fontWeight="500">
                        {address.slice(0, 8) + '...' + address.slice(36, 42)}
                      </TYPE.main>
                      <CopyHelper toCopy={address} />
                    </AutoRow>
                  </Column>
                  <ButtonLight color={backgroundColor}>
                    <Link color={backgroundColor} external href={process.env.REACT_APP_EXPLORER + '/address/' + address}>
                      View on Etherscan ↗
                    </Link>
                  </ButtonLight>
                </TokenDetailsLayout>
              </Panel>
            )} */}
          {/* {!below800 && (
              <>
                <DashGrid style={{ height: 'fit-content' }} className="mt-16 bg-basic-1 rounded py-8 px-16">
                  <Flex>
                    <span className="uppercase text-basic-7 font-bold text-10 leading-16">symbol</span>
                  </Flex>
                  <Flex>
                    <span className="uppercase text-basic-7 font-bold text-10 leading-16">name</span>
                  </Flex>
                  <Flex>
                    <span className="uppercase text-basic-7 font-bold text-10 leading-16">address</span>
                  </Flex>
                  <Flex />
                </DashGrid>
                <DashGrid style={{ height: 63 }}>
                  <Flex alignItems="center">
                    <span className="text-14 leading-20 font-semibold">{symbol}</span>
                  </Flex>
                  <Flex alignItems="center">
                    <span className="text-14 leading-20 font-semibold">{name}</span>
                  </Flex>
                  <Flex alignItems="center">
                    <div className="text-14 leading-20 font-semibold flex items-center" style={{ zIndex: 9 }}>
                      {below800
                        ? address.replace('0x', 'ronin:').slice(0, 7) + '...' + address.slice(38, 42)
                        : below1280
                        ? address.replace('0x', 'ronin:').slice(0, 20) + '...' + address.slice(38, 42)
                        : address.replace('0x', 'ronin:')}
                      <Tooltip isOpen={showCopied} content="Copied">
                        <CopyIcon
                          className="text-basic-7 hover:bg-basic-1 cursor-pointer ml-4"
                          onClick={handleClickCopy}
                        />
                      </Tooltip>
                    </div>
                  </Flex>
                  <Flex className="justify-end" alignItems="center">
                    <Link
                      href={`${process.env.REACT_APP_EXPLORER}/token/${address.replace('0x', 'ronin:')}`}
                      target="_blank"
                    >
                      <Button rightIcon={ExternalLinkIcon} size={Button.sizes.Small}>
                        View on Ronin
                      </Button>
                    </Link>
                  </Flex>
                </DashGrid>
                <Divider />
              </>
            )} */}
          {/* </> */}
        </DashboardWrapper>
        {/* </WarningGrouping> */}
      </ContentWrapper>
    </PageWrapper>
  )
}

export default withRouter(TokenPage)
