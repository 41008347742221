import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import 'feather-icons'
import styled from 'styled-components'
import Panel from '../components/Panel'
import {
  PageWrapper,
  ContentWrapperLarge,
  StyledIcon,
  BlockedWrapper,
  BlockedMessageWrapper,
} from '../components/index'
import { AutoRow, RowBetween, RowFixed } from '../components/Row'
import Column, { AutoColumn } from '../components/Column'
import { ButtonLight, ButtonDark } from '../components/ButtonStyled'
import PairChart from '../components/PairChart'
import Link from '../components/Link'
import TxnList from '../components/TxnList'
import Loader from '../components/LocalLoader'
import { BasicLink } from '../components/Link'
import Search from '../components/Search'
import { formattedNum, formattedPercent, getPoolLink, getSwapLink, shortenAddress } from '../utils'
import { useColor } from '../hooks'
import { usePairData, usePairTransactions } from '../contexts/PairData'
// import { TYPE, ThemedBackground } from '../Theme'
import { TYPE } from '../Theme'
import { transparentize } from 'polished'
import CopyHelper from '../components/Copy'
import { useMedia } from 'react-use'
import DoubleTokenLogo from '../components/DoubleLogo'
import TokenLogo from '../components/TokenLogo'
import { Hover } from '../components'
import { Text } from 'rebass'
import { useEthPrice } from '../contexts/GlobalData'
import Warning from '../components/Warning'
import { usePathDismissed, useSavedPairs } from '../contexts/LocalStorage'

import { Bookmark, PlusCircle, AlertCircle } from 'react-feather'
import FormattedName from '../components/FormattedName'
import { useListedTokens } from '../contexts/Application'
import HoverText from '../components/HoverText'
import { UNTRACKED_COPY, PAIR_BLACKLIST, BLOCKED_WARNINGS } from '../constants'
import { ChevronRightIcon } from '@axieinfinity/sm-design-system/icons/ChevronRight'
import { MagnifierIcon } from '@axieinfinity/sm-design-system/icons/Magnifier'
import { ViewOnExplorerButton } from '../components/ViewOnExplorerButton'
import { Button, IntentType } from '@axieinfinity/sm-design-system'
import { PlusIcon } from '@axieinfinity/sm-design-system/icons/Plus'
import { TrendStatus } from '../components/TrendStatus'
import { ExternalLinkIcon } from '@axieinfinity/sm-design-system/icons/ExternalLink'

const DashboardWrapper = styled.div`
  width: 100%;
`

const PanelWrapper = styled.div`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 8px;
  display: inline-grid;
  width: 100%;
  align-items: start;
  @media screen and (max-width: 1024px) {
    gap: 16px;
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      /* grid-column: 1 / 4; */
    }

    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
`

const TokenDetailsLayout = styled.div`
  display: inline-grid;
  width: 100%;
  grid-template-columns: auto auto auto auto 1fr;
  column-gap: 60px;
  align-items: start;

  &:last-child {
    align-items: center;
    justify-items: end;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      /* grid-column: 1 / 4; */
      margin-bottom: 1rem;
    }

    &:last-child {
      align-items: start;
      justify-items: start;
    }
  }
`

const FixedPanel = styled(Panel)`
  width: fit-content;
  padding: 8px 12px;
  border-radius: 10px;

  background-color: var(--color-basic-1);
  :hover {
    cursor: pointer;
    /* background-color: ${({ theme }) => theme.bg2}; */
  }
`

const HoverSpan = styled.span`
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const WarningIcon = styled(AlertCircle)`
  stroke: ${({ theme }) => theme.text1};
  height: 16px;
  width: 16px;
  opacity: 0.6;
`

const WarningGrouping = styled.div`
  opacity: ${({ disabled }) => disabled && '0.4'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

function PairPage({ pairAddress, history }) {
  const {
    token0,
    token1,
    reserve0,
    reserve1,
    reserveUSD,
    trackedReserveUSD,
    oneDayVolumeUSD,
    volumeChangeUSD,
    oneDayVolumeUntracked,
    volumeChangeUntracked,
    liquidityChangeUSD,
  } = usePairData(pairAddress)

  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])
  const [showSearch, setShowSearch] = useState(false)
  const transactions = usePairTransactions(pairAddress)
  const backgroundColor = useColor(pairAddress)

  const formattedLiquidity = reserveUSD ? formattedNum(reserveUSD, true) : formattedNum(trackedReserveUSD, true)
  const usingUntrackedLiquidity = !trackedReserveUSD && !!reserveUSD
  // const liquidityChange = formattedPercent(liquidityChangeUSD)
  const liquidityChange = <TrendStatus value={liquidityChangeUSD} />

  // volume
  const volume = !!oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD, true) : formattedNum(oneDayVolumeUntracked, true)
  const usingUtVolume = oneDayVolumeUSD === 0 && !!oneDayVolumeUntracked
  // const volumeChange = formattedPercent(!usingUtVolume ? volumeChangeUSD : volumeChangeUntracked)
  const volumeChange = <TrendStatus value={!usingUtVolume ? volumeChangeUSD : volumeChangeUntracked} />

  const showUSDWaning = usingUntrackedLiquidity | usingUtVolume

  // get fees	  // get fees
  const fees =
    oneDayVolumeUSD || oneDayVolumeUSD === 0
      ? usingUtVolume
        ? formattedNum(oneDayVolumeUntracked * 0.003, true)
        : formattedNum(oneDayVolumeUSD * 0.003, true)
      : '-'

  // token data for usd
  const [ethPrice] = useEthPrice()
  const token0USD =
    token0?.derivedETH && ethPrice ? formattedNum(parseFloat(token0.derivedETH) * parseFloat(ethPrice), true) : ''

  const token1USD =
    token1?.derivedETH && ethPrice ? formattedNum(parseFloat(token1.derivedETH) * parseFloat(ethPrice), true) : ''

  // rates
  const token0Rate = reserve0 && reserve1 ? formattedNum(reserve1 / reserve0) : '-'
  const token1Rate = reserve0 && reserve1 ? formattedNum(reserve0 / reserve1) : '-'

  // formatted symbols for overflow
  const formattedSymbol0 = token0?.symbol.length > 6 ? token0?.symbol.slice(0, 5) + '...' : token0?.symbol
  const formattedSymbol1 = token1?.symbol.length > 6 ? token1?.symbol.slice(0, 5) + '...' : token1?.symbol

  const below1080 = useMedia('(max-width: 1080px)')
  const below900 = useMedia('(max-width: 900px)')
  const below600 = useMedia('(max-width: 600px)')

  const [dismissed, markAsDismissed] = usePathDismissed(history.location.pathname)

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  const [savedPairs, addPair] = useSavedPairs()

  const listedTokens = useListedTokens()

  if (PAIR_BLACKLIST.includes(pairAddress)) {
    return (
      <BlockedWrapper>
        <BlockedMessageWrapper>
          <AutoColumn gap="1rem" justify="center">
            <TYPE.light style={{ textAlign: 'center' }}>
              {BLOCKED_WARNINGS[pairAddress] ?? `This pair is not supported.`}
            </TYPE.light>
            <Link
              external={true}
              shouldRemoveRelAttr
              href={process.env.REACT_APP_EXPLORER + '/address/' + pairAddress}
            >{`More about ${shortenAddress(pairAddress)}`}</Link>
          </AutoColumn>
        </BlockedMessageWrapper>
      </BlockedWrapper>
    )
  }

  return (
    <PageWrapper>
      {/* <ThemedBackground backgroundColor={transparentize(0.6, backgroundColor)} /> */}
      <span />
      {/* <Warning
        type={'pair'}
        show={!dismissed && listedTokens && !(listedTokens.includes(token0?.id) && listedTokens.includes(token1?.id))}
        setShow={markAsDismissed}
        address={pairAddress}
      /> */}
      <ContentWrapperLarge style={{ gridGap: 0 }}>
        <RowBetween className="items-center">
          <div className="flex items-center">
            <TYPE.body color="var(--color-basic-7)!important" fontWeight={600} fontSize={14} lineHeight="20px">
              <BasicLink to="/pairs" color="var(--color-basic-7)">
                {'Pairs '}
              </BasicLink>{' '}
            </TYPE.body>
            <ChevronRightIcon className="text-basic-4 mx-8" size={20} />
            <span
              className="text-14 leading-20 font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap"
              style={{ maxWidth: 120 }}
            >
              {token0?.symbol}-{token1?.symbol} Pair
            </span>
            <Link
              style={{ width: 'fit-content' }}
              color={backgroundColor}
              className="hover:no-underline"
              fontSize={14}
              external
              shouldRemoveRelAttr
              href={`${process.env.REACT_APP_EXPLORER}/address/${pairAddress}`}
            >
              <Text style={{ marginLeft: '.15rem' }} fontSize={below600 ? '10px' : '14px'} fontWeight={400}>
                ({pairAddress.slice(0, 6) + '...' + pairAddress.slice(38, 42)})
              </Text>
            </Link>
          </div>
          {!below600 && showSearch && <Search small={true} onClickOutsideSearch={() => setShowSearch(false)} />}
          {!below600 && !showSearch && (
            <MagnifierIcon onClick={() => setShowSearch(true)} className="cursor-pointer text-basic-7" size={32} />
          )}
        </RowBetween>
        {/* <WarningGrouping
          disabled={
            !dismissed && listedTokens && !(listedTokens.includes(token0?.id) && listedTokens.includes(token1?.id))
          }
        > */}
        <DashboardWrapper className="mt-28">
          <AutoColumn gap="40px" style={{ marginBottom: '1.5rem' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              <RowFixed style={{ flexWrap: 'wrap', minWidth: '100px', alignItems: 'center' }}>
                <RowFixed>
                  {token0 && token1 && (
                    <DoubleTokenLogo
                      a0={token0?.id || ''}
                      a1={token1?.id || ''}
                      size={below600 ? 32 : 44}
                      height={56}
                      iconSize={below600 ? 20 : 29.33}
                      margin={true}
                    />
                  )}{' '}
                  <div style={{ marginLeft: 22 }} className="flex justify-center flex-col">
                    <TYPE.main
                      fontSize={below1080 ? '16px' : '24px'}
                      lineHeight={below1080 ? '24px' : '36px'}
                      fontWeight={600}
                    >
                      {token0 && token1 ? (
                        <>
                          <HoverSpan onClick={() => history.push(`/token/${token0?.id}`)}>{token0.symbol}</HoverSpan>
                          <span>-</span>
                          <HoverSpan onClick={() => history.push(`/token/${token1?.id}`)}>
                            {token1.symbol}
                          </HoverSpan>{' '}
                          Pair
                        </>
                      ) : (
                        ''
                      )}
                    </TYPE.main>
                    <div className="hidden md:flex">
                      <ViewOnExplorerButton address={`${process.env.REACT_APP_EXPLORER}/address/${pairAddress}`} />
                    </div>
                  </div>
                </RowFixed>
              </RowFixed>
              <div className="md:hidden mt-16">
                <Link href={`${process.env.REACT_APP_EXPLORER}/address/${pairAddress}`} target="_blank">
                  <Button rightIcon={ExternalLinkIcon}>View on Ronin</Button>
                </Link>
              </div>
              <RowFixed
                ml={below900 ? '0' : '2.5rem'}
                mt={below1080 && '1rem'}
                style={{
                  flexDirection: below1080 ? 'row' : 'initial',
                }}
              >
                {/* {!!!savedPairs[pairAddress] && !below1080 ? (
                  <Hover onClick={() => addPair(pairAddress, token0.id, token1.id, token0.symbol, token1.symbol)}>
                    <StyledIcon>
                      <PlusCircle style={{ marginRight: '0.5rem' }} />
                    </StyledIcon>
                  </Hover>
                ) : !below1080 ? (
                  <StyledIcon>
                    <Bookmark style={{ marginRight: '0.5rem', opacity: 0.4 }} />
                  </StyledIcon>
                ) : (
                  <></>
                )} */}

                <Link external href={getPoolLink(token0?.id, token1?.id)} shouldRemoveRelAttr>
                  {/* <ButtonLight color={backgroundColor}>+ Add Liquidity</ButtonLight> */}
                  <Button leftIcon={PlusIcon}>Add Liquidity</Button>
                </Link>
                <Link external href={getSwapLink(token0?.id, token1?.id)} shouldRemoveRelAttr>
                  {/* <ButtonDark ml={!below1080 && '.5rem'} mr={below1080 && '.5rem'} color={backgroundColor}>
                    Trade
                  </ButtonDark> */}
                  <Button intent={IntentType.Primary} className="ml-20">
                    Trade
                  </Button>
                </Link>
              </RowFixed>
            </div>
          </AutoColumn>
          <AutoRow
            gap="6px"
            style={{
              width: 'fit-content',
              marginTop: below900 ? '1rem' : '0',
              marginBottom: below900 ? '0' : '1.125rem',
              flexWrap: 'wrap',
            }}
          >
            <FixedPanel onClick={() => history.push(`/token/${token0?.id}`)}>
              <RowFixed>
                <TokenLogo address={token0?.id} size={20} />
                <TYPE.main fontSize={'14px'} lineHeight="20px" fontWeight={600} ml={'4px'} color="var(--color-basic-7)">
                  {token0 && token1 ? (
                    <>
                      {`1 ${formattedSymbol0}`}
                      <span className="font-normal">
                        {' '}
                        ={' '}
                        {`${token0Rate} ${formattedSymbol1} ${
                          parseFloat(token0?.derivedETH) ? '(' + token0USD + ')' : ''
                        }`}
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </TYPE.main>
              </RowFixed>
            </FixedPanel>
            <FixedPanel onClick={() => history.push(`/token/${token1?.id}`)}>
              <RowFixed>
                <TokenLogo address={token1?.id} size={20} />
                <TYPE.main fontSize={'14px'} lineHeight={1.25} fontWeight={600} ml={'4px'} color="var(--color-basic-7)">
                  {token0 && token1 ? (
                    <>
                      {`1 ${formattedSymbol1}`}
                      <span className="font-normal">
                        {' '}
                        ={' '}
                        {`${token1Rate} ${formattedSymbol0}  ${
                          parseFloat(token1?.derivedETH) ? '(' + token1USD + ')' : ''
                        }`}
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </TYPE.main>
              </RowFixed>
            </FixedPanel>
          </AutoRow>
          <>
            {!below1080 && (
              <RowFixed>
                <TYPE.main fontSize={'1.125rem'} mr="6px" fontWeight={600} lineHeight="28px">
                  Pair Stats
                </TYPE.main>
                {showUSDWaning ? (
                  <HoverText text={UNTRACKED_COPY}>
                    <WarningIcon />
                  </HoverText>
                ) : null}
              </RowFixed>
            )}
            <PanelWrapper style={{ marginTop: '.5rem' }}>
              <Panel style={{ height: '100%' }} hasBorder>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main
                      color="var(--color-basic-7)"
                      fontWeight={700}
                      className="uppercase"
                      fontSize={10}
                      lineHeight="16px"
                    >
                      Total Liquidity{' '}
                    </TYPE.main>
                    <div />
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.main fontSize={'1.5rem'} lineHeight="36px" fontWeight={600}>
                      {formattedLiquidity}
                    </TYPE.main>
                    {/* <TYPE.main>{liquidityChange}</TYPE.main> */}
                    {liquidityChange}
                  </RowBetween>
                </AutoColumn>
              </Panel>
              <Panel style={{ height: '100%' }} hasBorder>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main
                      color="var(--color-basic-7)"
                      fontWeight={700}
                      className="uppercase"
                      fontSize={10}
                      lineHeight="16px"
                    >
                      Volume (24hrs){' '}
                    </TYPE.main>
                    <div />
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.main fontSize={'1.5rem'} lineHeight="36px" fontWeight={600}>
                      {volume}
                    </TYPE.main>
                    {/* <TYPE.main>{volumeChange}</TYPE.main> */}
                    {volumeChange}
                  </RowBetween>
                </AutoColumn>
              </Panel>
              <Panel style={{ height: '100%' }} hasBorder>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main
                      color="var(--color-basic-7)"
                      fontWeight={700}
                      className="uppercase"
                      fontSize={10}
                      lineHeight="16px"
                    >
                      Fees (24hrs)
                    </TYPE.main>
                    <div />
                  </RowBetween>
                  <RowBetween align="flex-end">
                    <TYPE.main fontSize={'1.5rem'} lineHeight="36px" fontWeight={600}>
                      {fees}
                    </TYPE.main>
                    <TYPE.main>{volumeChange}</TYPE.main>
                  </RowBetween>
                </AutoColumn>
              </Panel>
              <Panel style={{ height: '100%' }} hasBorder>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <TYPE.main
                      color="var(--color-basic-7)"
                      fontWeight={700}
                      className="uppercase"
                      fontSize={10}
                      lineHeight="16px"
                    >
                      Pooled Tokens
                    </TYPE.main>
                    <div />
                  </RowBetween>
                  <Hover onClick={() => history.push(`/token/${token0?.id}`)} fade={true}>
                    <AutoRow gap="4px">
                      <div className="rounded bg-basic-1 flex items-center justify-center h-32 w-32">
                        <TokenLogo address={token0?.id} size={20} />
                      </div>
                      <TYPE.main fontSize={20} lineHeight="28px" fontWeight={600}>
                        <RowFixed>
                          {reserve0 ? formattedNum(reserve0) : ''}{' '}
                          <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} margin={true} />
                        </RowFixed>
                      </TYPE.main>
                    </AutoRow>
                  </Hover>
                  <Hover onClick={() => history.push(`/token/${token1?.id}`)} fade={true}>
                    <AutoRow gap="4px">
                      <div className="rounded bg-basic-1 flex items-center justify-center h-32 w-32">
                        <TokenLogo address={token1?.id} size={20} />
                      </div>
                      <TYPE.main fontSize={20} lineHeight="28px" fontWeight={600}>
                        <RowFixed>
                          {reserve1 ? formattedNum(reserve1) : ''}{' '}
                          <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} margin={true} />
                        </RowFixed>
                      </TYPE.main>
                    </AutoRow>
                  </Hover>
                </AutoColumn>
              </Panel>
              <Panel
                hasBorder
                style={{
                  gridColumn: below1080 ? '1' : '2/4',
                  gridRow: below1080 ? '' : '1/5',
                }}
              >
                <PairChart
                  address={pairAddress}
                  color={backgroundColor}
                  base0={reserve1 / reserve0}
                  base1={reserve0 / reserve1}
                />
              </Panel>
            </PanelWrapper>
            <TYPE.main
              fontSize={below900 ? '16px' : '24px'}
              style={{ marginTop: below900 ? '28px' : '48px' }}
              fontWeight={600}
              lineHeight={below900 ? '24px' : '28px'}
            >
              Transactions
            </TYPE.main>{' '}
            <Panel
              style={{
                marginTop: '1rem',
              }}
              p={0}
            >
              {transactions ? <TxnList transactions={transactions} /> : <Loader />}
            </Panel>
            {/* <RowBetween style={{ marginTop: '3rem' }}>
              <TYPE.main fontSize={'1.125rem'}>Pair Information</TYPE.main>{' '}
            </RowBetween>
            <Panel
              rounded
              style={{
                marginTop: '1.5rem',
              }}
              p={20}
            >
              <TokenDetailsLayout>
                <Column>
                  <TYPE.main>Pair Name</TYPE.main>
                  <TYPE.main style={{ marginTop: '.5rem' }}>
                    <RowFixed>
                      <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} />
                      -
                      <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} />
                    </RowFixed>
                  </TYPE.main>
                </Column>
                <Column>
                  <TYPE.main>Pair Address</TYPE.main>
                  <AutoRow align="flex-end">
                    <TYPE.main style={{ marginTop: '.5rem' }}>
                      {pairAddress.slice(0, 6) + '...' + pairAddress.slice(38, 42)}
                    </TYPE.main>
                    <CopyHelper toCopy={pairAddress} />
                  </AutoRow>
                </Column>
                <Column>
                  <TYPE.main>
                    <RowFixed>
                      <FormattedName text={token0?.symbol ?? ''} maxCharacters={8} />{' '}
                      <span style={{ marginLeft: '4px' }}>Address</span>
                    </RowFixed>
                  </TYPE.main>
                  <AutoRow align="flex-end">
                    <TYPE.main style={{ marginTop: '.5rem' }}>
                      {token0 && token0.id.slice(0, 6) + '...' + token0.id.slice(38, 42)}
                    </TYPE.main>
                    <CopyHelper toCopy={token0?.id} />
                  </AutoRow>
                </Column>
                <Column>
                  <TYPE.main>
                    <RowFixed>
                      <FormattedName text={token1?.symbol ?? ''} maxCharacters={8} />{' '}
                      <span style={{ marginLeft: '4px' }}>Address</span>
                    </RowFixed>
                  </TYPE.main>
                  <AutoRow align="flex-end">
                    <TYPE.main style={{ marginTop: '.5rem' }} fontSize={16}>
                      {token1 && token1.id.slice(0, 6) + '...' + token1.id.slice(38, 42)}
                    </TYPE.main>
                    <CopyHelper toCopy={token1?.id} />
                  </AutoRow>
                </Column>
                <ButtonLight color={backgroundColor}>
                  <Link color={backgroundColor} external href={process.env.REACT_APP_EXPLORER + '/address/' + pairAddress}>
                    View on Etherscan ↗
                  </Link>
                </ButtonLight>
              </TokenDetailsLayout>
            </Panel> */}
          </>
        </DashboardWrapper>
        {/* </WarningGrouping> */}
      </ContentWrapperLarge>
    </PageWrapper>
  )
}

export default withRouter(PairPage)
