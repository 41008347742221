import { IntentType, Tag } from '@axieinfinity/sm-design-system'
import React from 'react'
import { TrendDownIcon } from '@axieinfinity/sm-design-system/icons/TrendDown'
import { TrendUpIcon } from '@axieinfinity/sm-design-system/icons/TrendUp'
import { formattedPercent } from '../../utils'

export const TrendStatus = ({ value = 0, showIcon = true }) => {
  const parsedValue = parseFloat(value)

  return (
    <Tag
      color={!parsedValue ? undefined : parsedValue > 0 ? IntentType.Success : IntentType.Danger}
      minimal={!!parsedValue}
      leftIcon={!parsedValue || !showIcon ? undefined : parsedValue > 0 ? <TrendUpIcon /> : <TrendDownIcon />}
    >
      <span className="text-10 font-medium">{formattedPercent(value)}</span>
    </Tag>
  )
}
