import { Button } from '@axieinfinity/sm-design-system'
import { ExternalLinkIcon } from '@axieinfinity/sm-design-system/icons/ExternalLink'
import React from 'react'
import Link from '../Link'

export const ViewOnExplorerButton = ({ address }) => (
  <Link external href={address} className="hover:no-underline whitespace-nowrap" shouldRemoveRelAttr>
    <div
      className="rounded bg-basic-1 text-basic-7 w-fit-content h-20 flex items-center px-8"
      style={{ width: 'fit-content' }}
    >
      <ExternalLinkIcon className="mr-4 text-basic-7" style={{ minWidth: 16 }} />
      <span className="font-normal text-10 leading-16">View on Explorer</span>
    </div>
  </Link>
)
