import React from 'react'
import styled from 'styled-components'
import TokenLogo from '../TokenLogo'

export default function DoubleTokenLogo({ a0, a1, iconSize = 24, size = 40, height = 32, margin = false }) {
  const TokenWrapper = styled.div`
    height: ${({ height }) => height}px;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-right: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};
  `

  const HigherLogo = styled.div`
    z-index: 2;
    // background-color: white;
    background-color: ${({ address }) =>
      address === process.env.REACT_APP_AXS_ADDRESS
        ? 'var(--color-primary-2)'
        : address === process.env.REACT_APP_SLP_ADDRESS
        ? '#c3e3d5'
        : 'var(--color-basic-1)'};
    border-radius: 50%;
    width: ${({ sizeraw }) => sizeraw}px;
    height: ${({ sizeraw }) => sizeraw}px;
  `

  const CoveredLogo = styled.div`
    position: absolute;
    left: ${({ sizeraw }) => ((sizeraw / 3) * 2).toString() + 'px'};
    top: ${({ height }) => height / 3}px;
    // background-color: white;
    background-color: ${({ address }) =>
      address === process.env.REACT_APP_AXS_ADDRESS
        ? 'var(--color-primary-2)'
        : address === process.env.REACT_APP_SLP_ADDRESS
        ? '#c3e3d5'
        : 'var(--color-basic-1)'};
    border-radius: 50%;
    width: ${({ sizeraw }) => sizeraw}px;
    height: ${({ sizeraw }) => sizeraw}px;
  `

  return (
    <TokenWrapper sizeraw={size} margin={margin} height={height}>
      <HigherLogo address={a0} sizeraw={size} className="flex justify-center items-center">
        <TokenLogo address={a0} size={iconSize} />
      </HigherLogo>
      <CoveredLogo address={a1} height={height} sizeraw={size} className="flex justify-center items-center">
        <TokenLogo address={a1} size={iconSize} />
      </CoveredLogo>
    </TokenWrapper>
  )
}
