import React, { useState, useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import {
  useUserTransactions,
  useUserPositions,
  // , useMiningPositions
} from '../contexts/User'
import TxnList from '../components/TxnList'
import { Flex } from 'rebass'
import Panel from '../components/Panel'
import { formattedNum } from '../utils'
import Row, { AutoRow, RowFixed, RowBetween } from '../components/Row'
import { AutoColumn } from '../components/Column'
import UserChart from '../components/UserChart'
import PairReturnsChart from '../components/PairReturnsChart'
import PositionList from '../components/PositionList'
import MiningPositionList from '../components/MiningPositionList'
import { TYPE } from '../Theme'
import { ButtonDropdown, ButtonLight } from '../components/ButtonStyled'
import { PageWrapper, ContentWrapper, StyledIcon, Divider, FullWrapper } from '../components'
import DoubleTokenLogo from '../components/DoubleLogo'
import { Bookmark, Activity } from 'react-feather'
import Link from '../components/Link'
import { FEE_WARNING_TOKENS } from '../constants'
import { BasicLink } from '../components/Link'
import { useMedia } from 'react-use'
import Search from '../components/Search'
import { useSavedAccounts } from '../contexts/LocalStorage'
import { MagnifierIcon } from '@axieinfinity/sm-design-system/icons/Magnifier'
import { ChevronRightIcon } from '@axieinfinity/sm-design-system/icons/ChevronRight'
import { FilledPersonIcon } from '@axieinfinity/sm-design-system/icons/FilledPerson'
import { ExternalLinkIcon } from '@axieinfinity/sm-design-system/icons/ExternalLink'
import { Button } from '@axieinfinity/sm-design-system'
import { buttons } from 'polished'
import { ViewOnExplorerButton } from '../components/ViewOnExplorerButton'

const AccountWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 6px 16px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Header = styled.div``

const DashGridChart = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: minmax(180px, auto) 1fr;
  grid-template-areas: 'liquidity feeEarned';
  align-items: flex-start;
  /* padding: 20px 0; */

  > * {
    justify-content: flex-start;
    width: 100%;
  }
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: minmax(180px, auto) minmax(180px, auto) 1fr;
  grid-template-areas: 'valueSwapped totalFee totalTxn';
  align-items: flex-start;
  padding: ${({ padding = '20px 16px' }) => padding};

  > * {
    justify-content: flex-start;
    width: 100%;
  }
`

const DashboardWrapper = styled.div`
  width: 100%;
  max-width: calc(100vw - 32px);
`

const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  max-width: calc(100vw - 32px);
  border: 1px solid var(--color-basic-4);
  border-radius: 8px;
`

const Flyout = styled.div`
  position: absolute;
  top: 57px;
  left: -1px;
  width: 100%;
  /* background-color: ${({ theme }) => theme.bg1}; */
  background-color: white;
  z-index: 999;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 4px;
  border: 1px solid #edeef2;
  border-top: none;
`

const MenuRow = styled(Row)`
  width: 100%;
  padding: 12px 0;
  padding-left: 12px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.bg2};
  }
`

const PanelWrapper = styled.div`
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  gap: 6px;
  display: inline-grid;
  width: 100%;
  align-items: start;
`

const Warning = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  padding: 1rem;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 1rem;
  width: calc(100% - 2rem);
`

function AccountPage({ account }) {
  // get data for this account
  const transactions = useUserTransactions(account)
  const positions = useUserPositions(account)
  // const miningPositions = useMiningPositions(account)

  const [showSearch, setShowSearch] = useState(false)

  // get data for user stats
  const transactionCount = transactions?.swaps?.length + transactions?.burns?.length + transactions?.mints?.length

  // get derived totals
  let totalSwappedUSD = useMemo(() => {
    return transactions?.swaps
      ? transactions?.swaps.reduce((total, swap) => {
          return total + parseFloat(swap.amountUSD)
        }, 0)
      : 0
  }, [transactions])

  // if any position has token from fee warning list, show warning
  const [showWarning, setShowWarning] = useState(false)
  useEffect(() => {
    if (positions) {
      for (let i = 0; i < positions.length; i++) {
        if (
          FEE_WARNING_TOKENS.includes(positions[i].pair.token0.id) ||
          FEE_WARNING_TOKENS.includes(positions[i].pair.token1.id)
        ) {
          setShowWarning(true)
        }
      }
    }
  }, [positions])

  // settings for list view and dropdowns
  const hideLPContent = positions && positions.length === 0
  const [showDropdown, setShowDropdown] = useState(false)
  const [activePosition, setActivePosition] = useState()

  const dynamicPositions = activePosition ? [activePosition] : positions

  const aggregateFees = dynamicPositions?.reduce(function (total, position) {
    return total + position.fees.sum
  }, 0)

  const positionValue = useMemo(() => {
    return dynamicPositions
      ? dynamicPositions.reduce((total, position) => {
          return (
            total +
            (parseFloat(position?.liquidityTokenBalance) / parseFloat(position?.pair?.totalSupply)) *
              position?.pair?.reserveUSD
          )
        }, 0)
      : null
  }, [dynamicPositions])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  const below600 = useMedia('(max-width: 600px)')
  const below800 = useMedia('(max-width: 800px)')

  // adding/removing account from saved accounts
  const [savedAccounts, addAccount, removeAccount] = useSavedAccounts()
  const isBookmarked = savedAccounts.includes(account)
  const handleBookmarkClick = useCallback(() => {
    ;(isBookmarked ? removeAccount : addAccount)(account)
  }, [account, isBookmarked, addAccount, removeAccount])

  return (
    <PageWrapper>
      <FullWrapper>
        <ContentWrapper style={{ gridGap: 0, maxWidth: '100vw' }}>
          <RowBetween>
            <div className="flex items-center">
              <TYPE.body>
                <BasicLink to="/accounts">
                  <span className="text-basic-7 font-semibold text-14 leading-20">{'Accounts '}</span>
                </BasicLink>
              </TYPE.body>
              <ChevronRightIcon size={20} className="mx-8 text-basic-4" />
              <Link
                lineHeight="20px"
                fontSize="14"
                color="var(--color-basic-9)!important"
                href={process.env.REACT_APP_EXPLORER + '/address/' + account}
                target="_blank"
              >
                {' '}
                {account?.slice(0, 6) +
                  '...' +
                  account?.slice(38, 42)}{' '}
              </Link>
            </div>
            {!below600 && showSearch && <Search small={true} onClickOutsideSearch={() => setShowSearch(false)} />}
            {!below600 && !showSearch && (
              <MagnifierIcon
                className="text-basic-7 cursor-pointer"
                size={32}
                onClick={() => {
                  setShowSearch(true)
                }}
              />
            )}
          </RowBetween>
          <Header className="my-40 mb-20 md:mb-40">
            <RowBetween>
              <div className="flex items-center">
                <div
                  className="bg-basic-1 mr-12 md:mr-20 rounded-12 flex items-center justify-center"
                  style={{ height: below800 ? 32 : 64, width: below800 ? 32 : 64 }}
                >
                  <FilledPersonIcon size={below800 ? 20 : 40} className="text-basic-7" />
                </div>
                <div>
                  <TYPE.header
                    fontSize={below800 ? 16 : 24}
                    lineHeight={below800 ? '24px' : '36px'}
                    fontWeight={600}
                    className="md:mb-4"
                  >
                    {account?.slice(0, 6) + '...' + account?.slice(38, 42)}
                  </TYPE.header>
                  <div className="">
                    <ViewOnExplorerButton address={process.env.REACT_APP_EXPLORER + '/address/' + account} />
                  </div>
                  {/* <Link href={} target="_blank" className="flex items-center">
                  <TYPE.main fontSize={16} fontWeight={400} lineHeight="24px" color="var(--color-primary-5)">
                    View on Ronin
                  </TYPE.main>
                  <ExternalLinkIcon size={20} className="ml-8" />
                </Link> */}
                </div>
              </div>
              {/* <AccountWrapper>
              <StyledIcon>
                <Bookmark
                  onClick={handleBookmarkClick}
                  style={{ opacity: isBookmarked ? 0.8 : 0.4, cursor: 'pointer' }}
                />
              </StyledIcon>
            </AccountWrapper> */}
            </RowBetween>
            {/* <div className="md:hidden mt-16">
              <Link
                href={process.env.REACT_APP_EXPLORER + '/address/' + account.replace('0x', 'ronin:')}
                target="_blank"
              >
                <Button rightIcon={ExternalLinkIcon}>View on Ronin</Button>
              </Link>
            </div> */}
          </Header>
          <DashboardWrapper>
            {showWarning && <Warning>Fees cannot currently be calculated for pairs that include AMPL.</Warning>}
            {!hideLPContent && (
              <DropdownWrapper style={{ marginBottom: below800 ? 28 : '1rem' }}>
                <ButtonDropdown
                  style={{ height: 48, padding: '0 16px' }}
                  width="100%"
                  onClick={() => setShowDropdown(!showDropdown)}
                  open={showDropdown}
                >
                  {!activePosition && (
                    <RowFixed>
                      <StyledIcon>
                        <Activity size={24} color="var(--color-basic-6)" />
                      </StyledIcon>
                      <TYPE.body ml={'8px'} lineHeight="20px">
                        All Positions
                      </TYPE.body>
                    </RowFixed>
                  )}
                  {activePosition && (
                    <RowFixed>
                      <DoubleTokenLogo
                        a0={activePosition.pair.token0.id}
                        a1={activePosition.pair.token1.id}
                        size={24}
                        iconSize={16}
                      />
                      <TYPE.body ml={'16px'}>
                        {activePosition.pair.token0.symbol}-{activePosition.pair.token1.symbol} Position
                      </TYPE.body>
                    </RowFixed>
                  )}
                </ButtonDropdown>
                {showDropdown && (
                  <Flyout className="bg-white border border-basic-3 shadow-basic-3 rounded px-16 py-16">
                    <AutoColumn gap="0px">
                      {positions

                        // TODO: remove when release WRON
                        // ?.filter((p) => p.pair.token1.symbol !== 'WRON' && p.pair.token0.symbol !== 'WRON')
                        ?.map((p, i) => {
                          if (p.pair.token1.symbol === 'WRON') {
                            p.pair.token1.symbol = 'RON'
                          }
                          if (p.pair.token0.symbol === 'WRON') {
                            p.pair.token0.symbol = 'RON'
                          }
                          return (
                            p.pair.id !== activePosition?.pair.id && (
                              <MenuRow
                                className="rounded hover:bg-basic-2 py-8 mb-8"
                                onClick={() => {
                                  setActivePosition(p)
                                  setShowDropdown(false)
                                }}
                                key={i}
                              >
                                <DoubleTokenLogo a0={p.pair.token0.id} a1={p.pair.token1.id} iconSize={16} size={24} />
                                <TYPE.body ml={'16px'} fontSize={14} lineHeight="20px" fontWeight={600}>
                                  {p.pair.token0.symbol}-{p.pair.token1.symbol} Position
                                </TYPE.body>
                              </MenuRow>
                            )
                          )
                        })}
                      {activePosition && (
                        <MenuRow
                          onClick={() => {
                            setActivePosition()
                            setShowDropdown(false)
                          }}
                        >
                          <RowFixed>
                            <StyledIcon>
                              <Activity size={16} />
                            </StyledIcon>
                            <TYPE.body ml={'10px'}>All Positions</TYPE.body>
                          </RowFixed>
                        </MenuRow>
                      )}
                    </AutoColumn>
                  </Flyout>
                )}
              </DropdownWrapper>
            )}
            {!hideLPContent && (
              // <Panel style={{ height: '100%', marginBottom: '1rem' }}>
              //   <AutoRow gap="20px">
              //     <AutoColumn gap="10px">
              //       <RowBetween>
              //         <TYPE.body>Liquidity (Including Fees)</TYPE.body>
              //         <div />
              //       </RowBetween>
              //       <RowFixed align="flex-end">
              //         <TYPE.header fontSize={'24px'} lineHeight={1}>
              //           {positionValue
              //             ? formattedNum(positionValue, true)
              //             : positionValue === 0
              //             ? formattedNum(0, true)
              //             : '-'}
              //         </TYPE.header>
              //       </RowFixed>
              //     </AutoColumn>
              //     <AutoColumn gap="10px">
              //       <RowBetween>
              //         <TYPE.body>Fees Earned (Cumulative)</TYPE.body>
              //         <div />
              //       </RowBetween>
              //       <RowFixed align="flex-end">
              //         <TYPE.header fontSize={'24px'} lineHeight={1} color={aggregateFees && 'green'}>
              //           {aggregateFees ? formattedNum(aggregateFees, true, true) : '-'}
              //         </TYPE.header>
              //       </RowFixed>
              //     </AutoColumn>
              //   </AutoRow>
              // </Panel>
              <>
                {!below800 && (
                  <>
                    <DashGridChart className="px-16 py-8 bg-basic-1 rounded mt-40">
                      <Flex area="liquidity">
                        <span className="text-basic-7 font-bold uppercase text-10 leading-16">
                          Liquidity (Including Fees)
                        </span>
                      </Flex>
                      <Flex area="feeEarned">
                        <span className="text-basic-7 font-bold uppercase text-10 leading-16">
                          Fees Earned (Cumulative)
                        </span>
                      </Flex>
                    </DashGridChart>
                    <DashGridChart className="px-16 items-center" style={{ height: 63, alignItems: 'center' }}>
                      <Flex area="liquidity">
                        <span className="font-semibold text-20 leading-28">
                          {positionValue
                            ? formattedNum(positionValue, true)
                            : positionValue === 0
                            ? formattedNum(0, true)
                            : '-'}
                        </span>
                      </Flex>
                      <Flex area="feeEarned">
                        <span className={`font-semibold text-20 leading-28 ${aggregateFees && 'text-success-5'}`}>
                          {aggregateFees ? formattedNum(aggregateFees, true, true) : '-'}
                        </span>
                      </Flex>
                    </DashGridChart>
                    <Divider />
                  </>
                )}
                {below800 && (
                  <div className="border border-basic-3 rounded p-12">
                    <div className="uppercase text-10 leading-16 font-bold text-basic-7">
                      Liquidity (inlcuding Fees)
                    </div>
                    <div className="text-20 leading-28 font-semibold mt-4">
                      {positionValue
                        ? formattedNum(positionValue, true)
                        : positionValue === 0
                        ? formattedNum(0, true)
                        : '-'}
                    </div>
                    <div className="uppercase text-10 leading-16 font-bold text-basic-7 mt-16">
                      fees earned (cumulative)
                    </div>
                    <div className={`font-semibold text-20 leading-28 ${aggregateFees && 'text-success-5'} mt-4`}>
                      {aggregateFees ? formattedNum(aggregateFees, true, true) : '-'}
                    </div>
                  </div>
                )}
              </>
            )}
            {!hideLPContent && (
              <PanelWrapper className="mt-16 md:mt-48 p-0">
                <Panel style={{ gridColumn: '1' }} className="border border-basic-3 rounded">
                  {activePosition ? (
                    <PairReturnsChart account={account} position={activePosition} />
                  ) : (
                    <UserChart account={account} position={activePosition} />
                  )}
                </Panel>
              </PanelWrapper>
            )}
            <TYPE.main
              fontSize={below800 ? '16px' : '20px'}
              lineHeight={below800 ? '24px' : '28px'}
              fontWeight={600}
              style={{ marginTop: below800 ? '28px' : '48px' }}
            >
              Positions
            </TYPE.main>{' '}
            <Panel
              style={{
                marginTop: '1rem',
              }}
              p={0}
            >
              <PositionList positions={positions} />
            </Panel>
            {/* <TYPE.main fontSize={'20px'} lineHeight="28px" fontWeight={600} style={{ marginTop: '3rem' }}>
            Liquidity Mining Pools
          </TYPE.main>
          <Panel
            style={{
              marginTop: '1rem',
            }}
            p={0}
          >
            {miningPositions && <MiningPositionList miningPositions={miningPositions} />}
            {!miningPositions && (
              <AutoColumn gap="8px" justify="flex-start">
                <TYPE.main>No Staked Liquidity.</TYPE.main>
                <AutoRow gap="8px" justify="flex-start">
                  <ButtonLight style={{ padding: '4px 6px', borderRadius: '4px' }}>Learn More</ButtonLight>{' '}
                  <Button size={Button.sizes.Small}>Learn More</Button>{' '}
                </AutoRow>{' '}
              </AutoColumn>
            )}
          </Panel> */}
            <TYPE.main
              fontSize={below800 ? '16px' : '20px'}
              lineHeight={below800 ? '24px' : '28px'}
              fontWeight={600}
              style={{ marginTop: below800 ? '28px' : '48px' }}
            >
              Transactions
            </TYPE.main>{' '}
            <Panel
              style={{
                marginTop: '1rem',
              }}
              p={0}
            >
              <TxnList transactions={transactions} />
            </Panel>
            <TYPE.main
              fontSize={below800 ? '16px' : '20px'}
              lineHeight={below800 ? '24px' : '28px'}
              fontWeight={600}
              style={{ marginTop: below800 ? '28px' : '48px' }}
            >
              Wallet Stats
            </TYPE.main>{' '}
            <Panel
              style={{
                marginTop: '1rem',
              }}
              hasBorder={below800}
              p={below800 ? 12 : 0}
            >
              {below800 && (
                // <AutoRow gap="20px">
                //   <AutoColumn gap="8px">
                //     <TYPE.header fontSize={24}>{totalSwappedUSD ? formattedNum(totalSwappedUSD, true) : '-'}</TYPE.header>
                //     <TYPE.main>Total Value Swapped</TYPE.main>
                //   </AutoColumn>
                //   <AutoColumn gap="8px">
                //     <TYPE.header fontSize={24}>
                //       {totalSwappedUSD ? formattedNum(totalSwappedUSD * 0.003, true) : '-'}
                //     </TYPE.header>
                //     <TYPE.main>Total Fees Paid</TYPE.main>
                //   </AutoColumn>
                //   <AutoColumn gap="8px">
                //     <TYPE.header fontSize={24}>{transactionCount ? transactionCount : '-'}</TYPE.header>
                //     <TYPE.main>Total Transactions</TYPE.main>
                //   </AutoColumn>
                // </AutoRow>
                <>
                  <div className="uppercase text-10 leading-16 text-basic-7 font-bold">total value swapped</div>
                  <div className="text-14 leading-20 font-semibold mt-4 mb-16">
                    {totalSwappedUSD ? formattedNum(totalSwappedUSD, true) : '-'}
                  </div>
                  <div className="uppercase text-10 leading-16 text-basic-7 font-bold">total fees paid</div>
                  <div className="text-14 leading-20 font-semibold mt-4 mb-16">
                    {totalSwappedUSD ? formattedNum(totalSwappedUSD * 0.003, true) : '-'}
                  </div>
                  <div className="uppercase text-10 leading-16 text-basic-7 font-bold">total transactions</div>
                  <div className="text-14 leading-20 font-semibold mt-4">
                    {transactionCount ? transactionCount : '-'}
                  </div>
                </>
              )}
              {!below800 && (
                <>
                  <DashGrid className="px-16 py-8 rounded bg-basic-1 whitespace-nowrap" padding="8px 16px">
                    <Flex area="valueSwapped">
                      <span className="uppercase font-bold text-basic-7 text-10 leading-16">Total Value Swapped</span>
                    </Flex>
                    <Flex area="totalFee">
                      <span className="uppercase font-bold text-basic-7 text-10 leading-16">Total Fee Paid</span>
                    </Flex>
                    <Flex area="totalTxn">
                      <span className="uppercase font-bold text-basic-7 text-10 leading-16">Total Transactions</span>
                    </Flex>
                  </DashGrid>
                  <DashGrid className="px-16 whitespace-nowrap" style={{ height: 64 }}>
                    <Flex area="valueSwapped">
                      <span className="font-semibold text-20 leading-28">
                        {totalSwappedUSD ? formattedNum(totalSwappedUSD, true) : '-'}
                      </span>
                    </Flex>
                    <Flex area="totalFee">
                      <span className="font-semibold text-20 leading-28">
                        {totalSwappedUSD ? formattedNum(totalSwappedUSD * 0.003, true) : '-'}
                      </span>
                    </Flex>
                    <Flex area="totalTxn">
                      <span className="font-semibold text-20 leading-28">
                        {transactionCount ? transactionCount : '-'}
                      </span>
                    </Flex>
                  </DashGrid>
                  <Divider />
                </>
              )}
            </Panel>
          </DashboardWrapper>
        </ContentWrapper>
      </FullWrapper>
    </PageWrapper>
  )
}

export default AccountPage
