import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Box } from 'rebass'
import styled from 'styled-components'

import { AutoRow, RowBetween } from '../components/Row'
import { AutoColumn } from '../components/Column'
import PairList from '../components/PairList'
import TopTokenList from '../components/TokenList'
import TxnList from '../components/TxnList'
import GlobalChart from '../components/GlobalChart'
import Search from '../components/Search'
import GlobalStats from '../components/GlobalStats'

import { useGlobalData, useGlobalTransactions } from '../contexts/GlobalData'
import { useAllPairData } from '../contexts/PairData'
import { useMedia } from 'react-use'
import Panel from '../components/Panel'
import { useAllTokenData } from '../contexts/TokenData'
import { formattedNum, formattedPercent } from '../utils'
// import { TYPE, ThemedBackground } from '../Theme'
import { TYPE } from '../Theme'
import { transparentize } from 'polished'
import { CustomLink } from '../components/Link'

import { PageWrapper, ContentWrapper } from '../components'
import CheckBox from '../components/Checkbox'
import QuestionHelper from '../components/QuestionHelper'
import { Button } from '@axieinfinity/sm-design-system'
import { TrendStatus } from '../components/TrendStatus'
import { disabledFeature } from '../utils/featureConfigs'

const ListOptions = styled(AutoRow)`
  /* height: 40px; */
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
`

const GridRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`

function GlobalPage() {
  // get data for lists and totals
  const allPairs = useAllPairData()
  const allTokens = useAllTokenData()
  const transactions = useGlobalTransactions()
  const { totalLiquidityUSD, oneDayVolumeUSD, volumeChangeUSD, liquidityChangeUSD } = useGlobalData()

  // breakpoints
  const below800 = useMedia('(max-width: 800px)')

  // scrolling refs
  useEffect(() => {
    document.querySelector('body').scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  // for tracked data on pairs
  const [useTracked, setUseTracked] = useState(true)

  return (
    <PageWrapper>
      <ContentWrapper style={{ gridGap: 0 }}>
        <AutoColumn gap="16px" style={{ paddingBottom: below800 ? '0' : '16px' }}>
          <TYPE.largeHeader
            fontWeight={below800 ? 600 : 700}
            fontSize={below800 ? 24 : 28}
            lineHeight={below800 ? '38px' : '40px'}
          >
            {below800 ? 'Ronin Analytics' : 'Ronin Analytics'}
          </TYPE.largeHeader>
          <Search autoFocus={false} />
          <div className="mt-24 md:mb-24 mb-16">
            <GlobalStats />
          </div>
        </AutoColumn>
        {below800 && ( // mobile card
          <Box mb={16}>
            <Panel hasBorder p={12}>
              <Box>
                <AutoColumn gap="16px">
                  <AutoColumn gap="4px">
                    <RowBetween>
                      <TYPE.main
                        color="var(--color-basic-7)"
                        className="uppercase"
                        fontWeight={700}
                        fontSize={10}
                        lineHeight="16px"
                      >
                        Volume (24hrs)
                      </TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-center">
                      <TYPE.main fontSize="20px" lineHeight="28px" fontWeight={600}>
                        {oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD, true) : '-'}
                      </TYPE.main>
                      {/* <TYPE.main fontSize={12}>{volumeChangeUSD ? formattedPercent(volumeChangeUSD) : '-'}</TYPE.main> */}
                    </RowBetween>
                  </AutoColumn>
                  <AutoColumn gap="4px">
                    <RowBetween>
                      <TYPE.main
                        color="var(--color-basic-7)"
                        className="uppercase"
                        fontWeight={700}
                        fontSize={10}
                        lineHeight="16px"
                      >
                        Total Liquidity
                      </TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="center">
                      <TYPE.main fontSize="20px" lineHeight="28px" fontWeight={600}>
                        {totalLiquidityUSD ? formattedNum(totalLiquidityUSD, true) : '-'}
                      </TYPE.main>
                      <TYPE.main fontSize={12}>
                        <TrendStatus value={liquidityChangeUSD ? liquidityChangeUSD : 0} />
                      </TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </AutoColumn>
              </Box>
            </Panel>
          </Box>
        )}
        {!below800 && (
          <GridRow style={{ columnGap: 8 }}>
            <Panel style={{ height: '100%', minHeight: '300px' }} className="border rounded border-basic-4">
              <GlobalChart display="liquidity" />
            </Panel>
            <Panel style={{ height: '100%' }} className="border rounded border-basic-4">
              <GlobalChart display="volume" />
            </Panel>
          </GridRow>
        )}
        {below800 && (
          <AutoColumn gap="24px">
            <Panel style={{ height: '100%', minHeight: '300px' }} hasBorder p={12}>
              <GlobalChart display="liquidity" />
            </Panel>
          </AutoColumn>
        )}
        {/* <ListOptions gap="0px" className="mt-28 md:mt-48"> */}
        <div className="mt-28 md:mt-48">
          <RowBetween>
            <TYPE.main
              fontSize={below800 ? 16 : 20}
              lineHeight={below800 ? '24px' : '28px'}
              fontWeight={600}
              style={{ whiteSpace: 'nowrap' }}
            >
              Top Tokens
            </TYPE.main>
            {/* <CustomLink to={'/tokens'}>See All</CustomLink> */}
          </RowBetween>
        </div>
        {/* </ListOptions> */}
        <Panel style={{ marginTop: '16px', padding: 0 }}>
          <TopTokenList tokens={allTokens} />
        </Panel>
        {!disabledFeature.includes('pair') && (
          <div className="mt-28 md:mt-48 mb-16">
            <RowBetween>
              <TYPE.main
                fontSize={below800 ? '16px' : '20px'}
                lineHeight={below800 ? '24px' : '28px'}
                fontWeight="600"
                style={{ whiteSpace: 'nowrap' }}
              >
                Top Pairs
              </TYPE.main>
              <AutoRow width="100%" justifyContent="flex-end" className="m-0">
                <CustomLink to={'/pairs'}>
                  <Button size={Button.sizes.Small}>See All</Button>
                </CustomLink>
              </AutoRow>
            </RowBetween>
          </div>
        )}
        <Panel style={{ padding: 0 }}>
          <PairList pairs={allPairs} useTracked={useTracked} />
        </Panel>
        <span className="mt-28 md:mt-48">
          <TYPE.main fontSize={below800 ? '16px' : '20px'} lineHeight={below800 ? '24px' : '28px'} fontWeight="600">
            Transactions
          </TYPE.main>
        </span>
        <Panel style={{ margin: '1rem 0', padding: 0 }}>
          <TxnList transactions={transactions} />
        </Panel>
      </ContentWrapper>
    </PageWrapper>
  )
}

export default withRouter(GlobalPage)
