import React, { useCallback, useState } from 'react'
import { HelpCircle as Question } from 'react-feather'
import styled from 'styled-components'
import Popover, { PopoverProps } from '../Popover'
import { Tooltip } from '@axieinfinity/sm-design-system'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.2rem; */
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const TooltipContainer = styled.div`
  width: 228px;
  padding: 0.6rem 1rem;
  line-height: 150%;
  font-weight: 400;
`

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text: string
}

// export function Tooltip({ text, ...rest }: TooltipProps) {
//   return <Popover content={<TooltipContainer>{text}</TooltipContainer>} {...rest} />
// }

export default function QuestionHelper({
  text,
  size = 16,
  disabled,
}: {
  text: string
  disabled?: boolean
  size: number
}) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ marginLeft: 4, lineHeight: size + 'px', maxHeight: size + 'px' }}>
      <Tooltip content={text} isOpen={show && !disabled}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <Question size={size} />
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}
