import React, { useState } from 'react'
import styled from 'styled-components'
import { RowFixed, RowBetween } from '../Row'
import { useMedia } from 'react-use'
import { useGlobalData, useEthPrice } from '../../contexts/GlobalData'
import { formattedNum, localNumber } from '../../utils'

import UniPrice from '../UniPrice'
import { TYPE } from '../../Theme'
import { useTokenData, useTokenPriceData } from '../../contexts/TokenData'
import TokenLogo from '../TokenLogo'
import { TransferIcon } from '@axieinfinity/sm-design-system/icons/Transfer'
import { FilledReceiptIcon } from '@axieinfinity/sm-design-system/icons/FilledReceipt'

const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
`

const Medium = styled.span`
  font-weight: 500;
`

export default function GlobalStats() {
  const below1295 = useMedia('(max-width: 1295px)')
  const below1180 = useMedia('(max-width: 1180px)')
  const below1024 = useMedia('(max-width: 1024px)')
  const below400 = useMedia('(max-width: 400px)')
  const below816 = useMedia('(max-width: 816px)')

  const [showPriceCard, setShowPriceCard] = useState(false)

  const { oneDayVolumeUSD, oneDayTxns, pairCount } = useGlobalData()
  const [ethPrice] = useEthPrice()
  const { priceUSD: axsPrice } = useTokenData(process.env.REACT_APP_AXS_ADDRESS)
  const { priceUSD: WRONPrice } = useTokenData(process.env.REACT_APP_WRON_ADDRESS)
  // const formattedEthPrice = ethPrice ? formattedNum(ethPrice, true) : '-'
  const formattedRONPrice = WRONPrice ? formattedNum(WRONPrice, true) : '-'
  const formattedAxsPrice = axsPrice ? formattedNum(axsPrice, true) : '-'
  const oneDayFees = oneDayVolumeUSD ? formattedNum(oneDayVolumeUSD * 0.003, true) : '-'

  return (
    <Header>
      <RowBetween
      // style={{ padding: below816 ? '0.5rem' : 0 }}
      >
        <RowFixed widthFull>
          {/* {!below400 && ( */}
          {/* <TYPE.main
              mr={'1rem'}
              // onMouseEnter={() => {
              //   setShowPriceCard(true)
              // }}
              // onMouseLeave={() => {
              //   setShowPriceCard(false)
              // }}
              style={{ position: 'relative' }}
            > */}
          {/* ETH Price: <Medium>{formattedEthPrice}</Medium> */}
          {/* </TYPE.main>  */}
          {/* )} */}
          <div
            className="md:mr-20 w-full md:w-auto border border-basic-3 rounded md:border-none p-12 md:p-0"
            style={{ minWidth: 212 }}
          >
            <div className="flex items-center">
              <div className="bg-basic-1 rounded md:h-40 md:w-40 flex justify-center items-center mr-12 w-32 h-32">
                <TokenLogo address={process.env.REACT_APP_WRON_ADDRESS} size={!below816 ? 24 : 20} />
              </div>
              <div className="flex flex-col">
                <span className="text-14 leading-20 font-semibold md:text-10 md:uppercase md:font-bold md:text-basic-7 md:leading-16">
                  RON Price
                </span>
                <span className="hidden md:block font-semibold leading-20 text-14">{formattedRONPrice}</span>
                {/* {showPriceCard && <UniPrice />} */}
              </div>
            </div>
            <div className="md:hidden mt-12 font-semibold text-20 leading-28">{formattedRONPrice}</div>
          </div>
          {!below816 && (
            // <TYPE.main
            //   mr={'1rem'}
            //   // onMouseEnter={() => {
            //   //   setShowPriceCard(true)
            //   // }}
            //   // onMouseLeave={() => {
            //   //   setShowPriceCard(false)
            //   // }}
            //   style={{ position: 'relative' }}
            // >
            // {/* ETH Price: <Medium>{formattedEthPrice}</Medium> */}
            // </TYPE.main>
            <div className="flex items-center mr-20" style={{ minWidth: 212 }}>
              <div className="bg-basic-1 rounded h-40 w-40 flex justify-center items-center mr-12">
                <TokenLogo address={process.env.REACT_APP_AXS_ADDRESS} />
              </div>
              <div className="flex flex-col">
                <span className="text-10 uppercase font-bold text-basic-7 leading-16">AXS Price</span>
                <span className="font-semibold leading-20 text-14">{formattedAxsPrice}</span>
                {/* {showPriceCard && <UniPrice />} */}
              </div>
            </div>
          )}

          {!below816 && (
            <div className="flex items-center mr-20" style={{ minWidth: 212 }}>
              <div className="bg-basic-1 rounded h-40 w-40 flex justify-center items-center mr-12">
                <TransferIcon size={24} className="text-primary-5" />
              </div>
              <div className="flex flex-col">
                <span className="text-10 uppercase font-bold text-basic-7 leading-16">Transactions (24H)</span>
                <span className="font-semibold leading-20 text-14">{localNumber(oneDayTxns)}</span>
                {/* {showPriceCard && <UniPrice />} */}
              </div>
            </div>
            // <TYPE.main mr={'1rem'}>
            //   : <Medium></Medium>
            // </TYPE.main>
          )}
          {/* {!below1024 && (
            <TYPE.main mr={'1rem'}>
              Pairs: <Medium>{localNumber(pairCount)}</Medium>
            </TYPE.main>
          )} */}
          {!below816 && (
            <div className="flex items-center">
              <div className="bg-basic-1 rounded h-40 w-40 flex justify-center items-center mr-12">
                <FilledReceiptIcon size={24} className="text-primary-5" />
              </div>
              <div className="flex flex-col">
                <span className="text-10 uppercase font-bold text-basic-7 leading-16">Fees (24H)</span>
                <span className="font-semibold leading-20 text-14">{oneDayFees}</span>
                {/* {showPriceCard && <UniPrice />} */}
              </div>
            </div>
            // <TYPE.main mr={'1rem'}>
            //   Fees (24H): <Medium>{oneDayFees}</Medium>&nbsp;
            // </TYPE.main>
          )}
        </RowFixed>
      </RowBetween>
    </Header>
  )
}
