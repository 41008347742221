import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../Column'
import Title from '../Title'
import { BasicLink } from '../Link'
import { useLocation, useMedia } from 'react-use'
import { transparentize } from 'polished'
import { TYPE } from '../../Theme'
import { withRouter } from 'react-router-dom'
import { TrendingUp, List, PieChart, Disc } from 'react-feather'
import Link from '../Link'
import { useSessionStart } from '../../contexts/Application'
import { useHistory } from 'react-router-dom'
import { useDarkModeManager } from '../../contexts/LocalStorage'
import Toggle from '../Toggle'
import { Menu, MenuItem } from '@axieinfinity/sm-design-system'
import { FilledBarChartIcon } from '@axieinfinity/sm-design-system/icons/FilledBarChart'
import { FilledPersonIcon } from '@axieinfinity/sm-design-system/icons/FilledPerson'
import { ReactComponent as FilledTokenIcon } from '../../assets/filled-token.svg'
import { ReactComponent as FilledCoinPairIcon } from '../../assets/filled-coin-pair.svg'
import logoImg from '../../assets/ronin-blacktext.svg'
import { MobileNav } from '../MobileNav'
import { DesktopButton } from '@axieinfinity/fe-plugins/wrapper/dist/DesktopButton'
import { disabledFeature } from '../../utils/featureConfigs'

const Wrapper = styled.div`
  height: ${({ isMobile }) => (isMobile ? 'initial' : '100vh')};
  /* background-color: ${({ theme }) => transparentize(0.4, theme.bg1)}; */
  color: ${({ theme }) => theme.text1};
  /* padding: 0.5rem 0.5rem 0.5rem 0.5rem; */
  position: sticky;
  top: 0px;
  z-index: 99999;
  box-sizing: border-box;
  /* background-color: #1b1c22; */
  /* background: linear-gradient(193.68deg, #1b1c22 0.68%, #000000 100.48%); */
  background-color: var(--color-basic-1);
  color: ${({ theme }) => theme.bg2};

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    position: relative;
  }

  /* @media screen and (max-width: 600px) {
    padding: 1rem;
  } */
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.white};
  display: flex;
  :hover {
    opacity: 1;
  }
`

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  .menu .ds-menu-item {
    color: var(--color-basic-7);
    svg {
      fill: var(--color-basic-7) !important;
      color: var(--color-basic-7);
    }
    &:hover,
    &.selected {
      svg {
        fill: var(--color-primary-5) !important;
      }
      color: var(--color-primary-5);
      background-color: var(--color-primary-1) !important;
    }
  }
`

const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderText = styled.div`
  margin-right: 0.75rem;
  font-size: 0.825rem;
  font-weight: 500;
  display: inline-box;
  display: -webkit-inline-box;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  a {
    color: ${({ theme }) => theme.white};
  }
`

export const Polling = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  align-items: center;
  padding: 1rem;
  color: var(--color-basic-7);
  font-size: 14px;
  line-height: 20px;
`
export const PollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-right: 1rem;
  border-radius: 50%;
  /* background-color: ${({ theme }) => theme.green1}; */
  background-color: var(--color-primary-5);
`

const getSelectedMenu = (pathname) => {
  const routes = {
    home: ['/home'],
    accounts: ['/accounts', '/account/'],
    tokens: ['/tokens', '/token/'],
    pairs: ['/pairs', '/pair/'],
  }
  if (!!routes.pairs.filter((route) => pathname.includes(route)).length) {
    return 'pairs'
  }
  if (!!routes.accounts.filter((route) => pathname.includes(route)).length) {
    return 'accounts'
  }
  if (!!routes.tokens.filter((route) => pathname.includes(route)).length) {
    return 'tokens'
  }
  return 'home'
}

function SideNav({ history }) {
  const below1080 = useMedia('(max-width: 1080px)')

  const below1180 = useMedia('(max-width: 1180px)')

  const seconds = useSessionStart()

  const location = useLocation()
  const router = useHistory()

  const [isDark, toggleDarkMode] = useDarkModeManager()
  const [selectedMenu, setSelectedMenu] = useState('home')

  const handleSelectMenu = (route, callback) => () => {
    if (callback) {
      callback()
    }
    router.push('/' + route)
  }

  useEffect(() => {
    setSelectedMenu(getSelectedMenu(location.pathname))
  }, [location])

  return (
    <Wrapper isMobile={below1080}>
      {!below1080 ? (
        <DesktopWrapper>
          <AutoColumn gap="0">
            {/* <Title /> */}
            <div className="flex p-20 pr-16 items-center justify-between">
              <img src={logoImg} height={60} className="cursor-pointer" onClick={handleSelectMenu('home')} />
              <DesktopButton />
            </div>

            {!below1080 && (
              <Menu className="w-full min-w-0 border-0 bg-basic-1 mt-12 menu" selectedItem={selectedMenu} noShadow>
                {/* <BasicLink to="/home"> */}
                <MenuItem
                  id="home"
                  label="Overview"
                  icon={FilledBarChartIcon}
                  className="font-semibold mb-8"
                  onClick={handleSelectMenu('home')}
                />
                {/* </BasicLink> */}
                {/* <BasicLink to="/tokens"> */}
                <MenuItem
                  id="tokens"
                  label="Tokens"
                  icon={FilledTokenIcon}
                  className="font-semibold mb-8"
                  onClick={handleSelectMenu('tokens')}
                />
                {/* </BasicLink> */}
                {/* <BasicLink to="/pairs"> */}
                {!disabledFeature.includes('pair') && (
                 <MenuItem
                    id="pairs"
                    label="Pairs"
                    icon={FilledCoinPairIcon}
                    className="font-semibold mb-8"
                    onClick={handleSelectMenu('pairs')}
                  />
                )}
                {/* </BasicLink> */}
                {/* <BasicLink to="/accounts"> */}
                <MenuItem
                  id="accounts"
                  label="Accounts"
                  icon={FilledPersonIcon}
                  className="font-semibold"
                  onClick={handleSelectMenu('accounts')}
                />
                {/* </BasicLink> */}
              </Menu>
              // {/* <AutoColumn gap="1.25rem" style={{ marginTop: '1rem' }}>
              //   <BasicLink to="/home">
              //     <Option activeText={history.location.pathname === '/home' ?? undefined}>
              //       <TrendingUp size={20} style={{ marginRight: '.75rem' }} />
              //       Overview
              //     </Option>
              //   </BasicLink>
              //   <BasicLink to="/tokens">
              //     <Option
              //       activeText={
              //         (history.location.pathname.split('/')[1] === 'tokens' ||
              //           history.location.pathname.split('/')[1] === 'token') ??
              //         undefined
              //       }
              //     >
              //       <Disc size={20} style={{ marginRight: '.75rem' }} />
              //       Tokens
              //     </Option>
              //   </BasicLink>
              //   <BasicLink to="/pairs">
              //     <Option
              //       activeText={
              //         (history.location.pathname.split('/')[1] === 'pairs' ||
              //           history.location.pathname.split('/')[1] === 'pair') ??
              //         undefined
              //       }
              //     >
              //       <PieChart size={20} style={{ marginRight: '.75rem' }} />
              //       Pairs
              //     </Option>
              //   </BasicLink>

              //   <BasicLink to="/accounts">
              //     <Option
              //       activeText={
              //         (history.location.pathname.split('/')[1] === 'accounts' ||
              //           history.location.pathname.split('/')[1] === 'account') ??
              //         undefined
              //       }
              //     >
              //       <List size={20} style={{ marginRight: '.75rem' }} />
              //       Accounts
              //     </Option>
              //   </BasicLink>
              // </AutoColumn> */}
            )}
          </AutoColumn>
          {/* <AutoColumn gap="0.5rem" style={{ marginLeft: '.75rem', marginBottom: '4rem' }}>
            <HeaderText>
              <Link href="https://uniswap.org" target="_blank">
                Uniswap.org
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://v1.uniswap.info" target="_blank">
                V1 Analytics
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://uniswap.org/docs/v2" target="_blank">
                Docs
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://discord.com/invite/FCfyBSbCU5" target="_blank">
                Discord
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://twitter.com/UniswapProtocol" target="_blank">
                Twitter
              </Link>
            </HeaderText>
            <Toggle isActive={isDark} toggle={toggleDarkMode} />
          </AutoColumn> */}
          {!below1180 && (
            <Polling style={{ marginLeft: '.5rem' }}>
              <PollingDot />
              {/* <a href="/" style={{ color: 'white' }}> */}
              Updated {!!seconds ? seconds + 's' : '-'} ago <br />
              {/* </a> */}
            </Polling>
          )}
        </DesktopWrapper>
      ) : (
        // <MobileWrapper>
        //   <Title />
        // </MobileWrapper>
        <MobileNav selectedItem={selectedMenu} onChangeMenu={handleSelectMenu} seconds={seconds} />
      )}
    </Wrapper>
  )
}

export default withRouter(SideNav)
