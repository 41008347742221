import React from 'react'
import { Link as RebassLink } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { lighten, darken } from 'polished'

const WrappedLink = ({ external, shouldRemoveRelAttr, children, ...rest }) => (
  <RebassLink
    target={external ? '_blank' : null}
    rel={external ? shouldRemoveRelAttr ? 'noopener' : 'noopener noreferrer' : null}
    color="#2f80ed"
    {...rest}
  >
    {children}
  </RebassLink>
)

WrappedLink.propTypes = {
  external: PropTypes.bool,
  shouldRemoveRelAttr: PropTypes.bool,
}

const Link = styled(WrappedLink)`
  color: ${({ color, theme }) => (color ? color : theme.link)};
  &:hover {
    text-decoration: none;
  }
`

export default Link

export const CustomLink = styled(RouterLink)`
  text-decoration: none;
  font-size: 14px;
  > * {
    color: ${({ color = 'var(--color-primary-5)' }) => color}!important;
    font-weight: ${({ fontWeight = 500 }) => fontWeight}!important;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
  }
`

export const BasicLink = styled(RouterLink)`
  text-decoration: none;
  color: ${({ color = 'inherit' }) => color + '!important'};
  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
  }
`
