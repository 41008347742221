import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { Box, Flex, Text } from 'rebass'
import TokenLogo from '../TokenLogo'
import { CustomLink } from '../Link'
import Row from '../Row'
import { Divider } from '..'

import { formattedNum, formattedPercent } from '../../utils'
import { useMedia } from 'react-use'
import { withRouter } from 'react-router-dom'
import { TOKEN_BLACKLIST } from '../../constants'
import FormattedName from '../FormattedName'
import { TYPE } from '../../Theme'
import { Pagination } from '@axieinfinity/sm-design-system'
import { TrendStatus } from '../TrendStatus'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.primary1};
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 100px 1fr 1fr;
  grid-template-areas: 'name liq vol';

  /* > * {
    justify-content: flex-end;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
      width: 100px;
    }
  } */

  @media screen and (min-width: 680px) {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 20px 180px 1fr 1fr 1fr;
    grid-template-areas: 'stt name symbol liq vol ';

    /* > * {
      justify-content: flex-end;
      width: 100%;

      &:first-child {
        justify-content: flex-start;
      }
    } */
  }

  @media screen and (min-width: 1080px) {
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: 20px 1.5fr 0.6fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'stt name symbol liq vol price change';
  }
`

const ListWrapper = styled.div``

const ClickableText = styled(Text)`
  text-transform: uppercase;
  text-align: center;
  &:hover {
    cursor: pointer;
    /* opacity: 0.6; */
    color: var(--color-primary-5);
  }
  user-select: none;
  /* color: ${({ theme }) => theme.text1} !important; */
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5;
  font-weight: 700;
  /* @media screen and (max-width: 640px) {
    font-size: 0.85rem;
  } */
`

const DataText = styled(Flex)`
  align-items: center;
  text-align: center;
  /* color: ${({ theme }) => theme.text1} !important; */

  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const SORT_FIELD = {
  LIQ: 'totalLiquidityUSD',
  VOL: 'oneDayVolumeUSD',
  VOL_UT: 'oneDayVolumeUT',
  SYMBOL: 'symbol',
  NAME: 'name',
  PRICE: 'priceUSD',
  CHANGE: 'priceChangeUSD',
}

// @TODO rework into virtualized list
function TopTokenList({ tokens, itemMax = 10, useTracked = false, showPagination = false }) {
  // page state
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  // sorting
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.LIQ)

  const below1080 = useMedia('(max-width: 1080px)')
  const below680 = useMedia('(max-width: 680px)')
  const below600 = useMedia('(max-width: 600px)')

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [tokens])

  const formattedTokens = useMemo(() => {
    return (
      tokens &&
      Object.keys(tokens)
        .filter((key) => {
          return !TOKEN_BLACKLIST.includes(key) && Object.keys(tokens[key]).length
        })
        .map((key) => tokens[key])
    )
  }, [tokens])

  useEffect(() => {
    if (tokens && formattedTokens) {
      let extraPages = 1
      if (formattedTokens.length % itemMax === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(formattedTokens.length / itemMax) + extraPages)
    }
  }, [tokens, formattedTokens, itemMax])

  const filteredList = useMemo(() => {
    return (
      formattedTokens &&
      formattedTokens
        .sort((a, b) => {
          if (sortedColumn === SORT_FIELD.SYMBOL || sortedColumn === SORT_FIELD.NAME) {
            return a[sortedColumn] > b[sortedColumn] ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
          }
          return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn])
            ? (sortDirection ? -1 : 1) * 1
            : (sortDirection ? -1 : 1) * -1
        })
        .slice(itemMax * (page - 1), page * itemMax)
      // TODO: remove when release WRON
      // .filter((token) => token.symbol !== 'WRON')
    )
  }, [formattedTokens, itemMax, page, sortDirection, sortedColumn])

  const ListItem = ({ item, index }) => {
    return (
      <DashGrid style={{ height: '64px' }} focus={true} className="px-16">
        {!below680 && (
          <DataText area="stt">
            <div style={{ marginRight: '1rem', width: '10px' }}>{index}</div>
          </DataText>
        )}
        <DataText area="name" fontWeight="400">
          <Row>
            <div className="h-32 w-32 rounded bg-basic-1 flex items-center justify-center">
              <TokenLogo address={item.id} size={20} />
            </div>
            <CustomLink
              color="var(--color-basic-9)"
              style={{ marginLeft: '12px', whiteSpace: 'nowrap' }}
              fontWeight={600}
              to={'/token/' + item.id}
            >
              <FormattedName
                text={below680 ? item.symbol : item.name}
                maxCharacters={below600 ? 8 : 50}
                adjustSize={true}
                link={true}
              />
            </CustomLink>
          </Row>
        </DataText>
        {!below680 && (
          <DataText area="symbol" fontWeight="400">
            <FormattedName text={item.symbol} maxCharacters={5} />
          </DataText>
        )}
        <DataText area="liq" className="justify-end md:justify-start">
          {formattedNum(item.totalLiquidityUSD, true)}
        </DataText>
        <DataText area="vol" className="justify-end md:justify-start">
          {formattedNum(item.oneDayVolumeUSD, true)}
        </DataText>
        {!below1080 && (
          <DataText area="price" fontWeight="400">
            {formattedNum(item.priceUSD, true)}
          </DataText>
        )}
        {/* {!below1080 && <DataText area="change" fontWeight="400">{formattedPercent(item.priceChangeUSD)}</DataText>} */}
        {!below1080 && (
          <DataText area="change">
            <TrendStatus value={item.priceChangeUSD} />
          </DataText>
        )}
      </DashGrid>
    )
  }

  return (
    <ListWrapper>
      {showPagination && (
        <div className="w-full whitespace-nowrap flex md:justify-end justify-center mb-12">
          <Pagination
            total={maxPage}
            limit={maxPage}
            hasLastFirst
            currentPage={page}
            onChangePage={(page) => setPage(page)}
          />
        </div>
      )}
      <DashGrid center={true} style={{ height: 'fit-content' }} className="bg-basic-1 rounded pt-8 pb-8 px-16">
        {!below680 && (
          <Flex>
            <ClickableText area="stt" />
          </Flex>
        )}
        <Flex alignItems="center" className="w-full">
          <ClickableText
            color={sortedColumn === SORT_FIELD.NAME ? 'var(--color-primary-5)' : 'var(--color-basic-7)'}
            area="name"
            fontWeight="700"
            marginLeft="44px"
            onClick={(e) => {
              setSortedColumn(SORT_FIELD.NAME)
              setSortDirection(sortedColumn !== SORT_FIELD.NAME ? true : !sortDirection)
            }}
          >
            {below680 ? 'Symbol' : 'Name'} {sortedColumn === SORT_FIELD.NAME ? (!sortDirection ? '↑' : '↓') : ''}
          </ClickableText>
        </Flex>
        {!below680 && (
          <Flex alignItems="center">
            <ClickableText
              area="symbol"
              color={sortedColumn === SORT_FIELD.SYMBOL ? 'var(--color-primary-5)' : 'var(--color-basic-7)'}
              onClick={() => {
                setSortedColumn(SORT_FIELD.SYMBOL)
                setSortDirection(sortedColumn !== SORT_FIELD.SYMBOL ? true : !sortDirection)
              }}
            >
              Symbol {sortedColumn === SORT_FIELD.SYMBOL ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
        )}

        <Flex alignItems="center" justifyContent={below680 ? 'flex-end' : undefined}>
          <ClickableText
            area="liq"
            onClick={(e) => {
              setSortedColumn(SORT_FIELD.LIQ)
              setSortDirection(sortedColumn !== SORT_FIELD.LIQ ? true : !sortDirection)
            }}
            color={sortedColumn === SORT_FIELD.LIQ ? 'var(--color-primary-5)' : 'var(--color-basic-7)'}
          >
            Liquidity {sortedColumn === SORT_FIELD.LIQ ? (!sortDirection ? '↑' : '↓') : ''}
          </ClickableText>
        </Flex>
        <Flex alignItems="center" justifyContent={below680 ? 'flex-end' : undefined}>
          <ClickableText
            area="vol"
            onClick={() => {
              setSortedColumn(useTracked ? SORT_FIELD.VOL_UT : SORT_FIELD.VOL)
              setSortDirection(
                sortedColumn !== (useTracked ? SORT_FIELD.VOL_UT : SORT_FIELD.VOL) ? true : !sortDirection
              )
            }}
            className="text-right md:text-center"
            color={
              sortedColumn === (useTracked ? SORT_FIELD.VOL_UT : SORT_FIELD.VOL)
                ? 'var(--color-primary-5)'
                : 'var(--color-basic-7)'
            }
          >
            Volume (24hrs)
            {sortedColumn === (useTracked ? SORT_FIELD.VOL_UT : SORT_FIELD.VOL) ? (!sortDirection ? '↑' : '↓') : ''}
          </ClickableText>
        </Flex>
        {!below1080 && (
          <Flex alignItems="center">
            <ClickableText
              area="price"
              onClick={(e) => {
                setSortedColumn(SORT_FIELD.PRICE)
                setSortDirection(sortedColumn !== SORT_FIELD.PRICE ? true : !sortDirection)
              }}
              color={sortedColumn === SORT_FIELD.PRICE ? 'var(--color-primary-5)' : 'var(--color-basic-7)'}
            >
              Price {sortedColumn === SORT_FIELD.PRICE ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
        )}
        {!below1080 && (
          <Flex alignItems="center">
            <ClickableText
              area="change"
              onClick={(e) => {
                setSortedColumn(SORT_FIELD.CHANGE)
                setSortDirection(sortedColumn !== SORT_FIELD.CHANGE ? true : !sortDirection)
              }}
              color={sortedColumn === SORT_FIELD.CHANGE ? 'var(--color-primary-5)' : 'var(--color-basic-7)'}
            >
              24h Price Change
              {sortedColumn === SORT_FIELD.CHANGE ? (!sortDirection ? '↑' : '↓') : ''}
            </ClickableText>
          </Flex>
        )}
      </DashGrid>
      {/* <Divider /> */}
      <List p={0}>
        {filteredList &&
          filteredList.map((item, index) => {
            return (
              <div key={index}>
                <ListItem key={index} index={(page - 1) * itemMax + index + 1} item={item} />
                <Divider />
              </div>
            )
          })}
      </List>
      {/* <PageButtons>
        <div onClick={() => setPage(page === 1 ? page : page - 1)}>
          <Arrow faded={page === 1 ? true : false}>←</Arrow>
        </div>
        <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
        <div onClick={() => setPage(page === maxPage ? page : page + 1)}>
          <Arrow faded={page === maxPage ? true : false}>→</Arrow>
        </div>
      </PageButtons> */}
    </ListWrapper>
  )
}

export default withRouter(TopTokenList)
