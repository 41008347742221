import React, { useEffect, useState } from 'react'
import 'feather-icons'
import { withRouter } from 'react-router-dom'
import { TYPE } from '../Theme'
import { PageWrapper, FullWrapper } from '../components'
import Panel from '../components/Panel'
import LPList from '../components/LPList'
import styled from 'styled-components'
import AccountSearch from '../components/AccountSearch'
import { useTopLps } from '../contexts/GlobalData'
import LocalLoader from '../components/LocalLoader'
import { RowBetween } from '../components/Row'
import { useMedia } from 'react-use'
import Search from '../components/Search'
import { Pagination } from '@axieinfinity/sm-design-system'
import { MagnifierIcon } from '@axieinfinity/sm-design-system/icons/Magnifier'

const AccountWrapper = styled.div`
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

function AccountLookup() {
  const [showSearch, setShowSearch] = useState(false)

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const topLps = useTopLps()

  const below600 = useMedia('(max-width: 600px)')

  return (
    <PageWrapper>
      <FullWrapper style={{ gridGap: 0 }}>
        <RowBetween className="mb-16" style={{ marginBottom: 16 }}>
          <TYPE.largeHeader>Wallet analytics</TYPE.largeHeader>
          {!below600 && showSearch && <Search small={true} onClickOutsideSearch={() => setShowSearch(false)} />}
          {!below600 && !showSearch && (
            <MagnifierIcon onClick={() => setShowSearch(true)} size={32} className="text-basic-7 cursor-pointer" />
          )}
        </RowBetween>
        <AccountWrapper>
          <AccountSearch />
        </AccountWrapper>
        <div className="flex flex-col mt-28 md:mt-48 md:flex-row items-start md:items-center justify-center md:justify-between">
          <TYPE.main lineHeight={below600 ? '24px' : '28px'} fontSize={below600 ? 16 : 20} fontWeight={600}>
            Top Liquidity Positions
          </TYPE.main>
          {/* <Pagination limit={} total={} currentPage={} onChangePage={} /> */}
        </div>
        <Panel padding="0" margin="16px 0">
          {topLps && topLps.length > 0 ? <LPList lps={topLps} maxItems={25} /> : <LocalLoader />}
        </Panel>
      </FullWrapper>
    </PageWrapper>
  )
}

export default withRouter(AccountLookup)
