import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.REACT_APP_SUBGRAPH_DOMAIN}/subgraphs/name/axieinfinity/katana-subgraph${process.env.REACT_APP_GREEN}`,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.REACT_APP_SUBGRAPH_DOMAIN}/index-node/graphql`,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const v1Client = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.REACT_APP_SUBGRAPH_DOMAIN}/subgraphs/name/axieinfinity/katana-subgraph${process.env.REACT_APP_GREEN}`,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.REACT_APP_SUBGRAPH_DOMAIN}/subgraphs/name/way2rach/talisman`,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.REACT_APP_SUBGRAPH_DOMAIN}/subgraphs/name/axieinfinity/ronin-blocks`,
  }),
  cache: new InMemoryCache(),
})
