import React, { useState } from 'react'
import 'feather-icons'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { ButtonLight, ButtonFaded } from '../ButtonStyled'
import { AutoRow, RowBetween } from '../Row'
import { isAddress } from '../../utils'
import { useSavedAccounts } from '../../contexts/LocalStorage'
import { AutoColumn } from '../Column'
import { TYPE } from '../../Theme'
import { Hover, StyledIcon } from '..'
import Panel from '../Panel'
import { Divider } from '..'
import { Flex } from 'rebass'
import { Button, Input } from '@axieinfinity/sm-design-system'

import { X } from 'react-feather'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-radius: 12px;
  .ds-input-wrapper {
    max-width: 100%;
    input {
      width: 100%;
    }
  }
`

// const Input = styled.input`
//   position: relative;
//   display: flex;
//   align-items: center;
//   width: 100%;
//   white-space: nowrap;
//   background: none;
//   border: none;
//   outline: none;
//   padding: 12px 16px;
//   border-radius: 12px;
//   color: ${({ theme }) => theme.text1};
//   background-color: ${({ theme }) => theme.bg1};
//   font-size: 16px;
//   margin-right: 1rem;
//   border: 1px solid ${({ theme }) => theme.bg3};

//   ::placeholder {
//     color: ${({ theme }) => theme.text3};
//     font-size: 14px;
//   }

//   @media screen and (max-width: 640px) {
//     ::placeholder {
//       font-size: 1rem;
//     }
//   }
// `

const AccountLink = styled.span`
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.link};
  font-size: 14px;
  font-weight: 400;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  grid-template-areas: 'account';

  > * {
    justify-content: flex-start;
  }
`

function AccountSearch({ history, small }) {
  const [accountValue, setAccountValue] = useState()
  const [savedAccounts, addAccount, removeAccount] = useSavedAccounts()

  function handleAccountSearch() {
    if (isAddress(accountValue.replace('ronin:', '0x'))) {
      history.push('/account/' + accountValue.replace('ronin:', '0x'))
      if (!savedAccounts.includes(accountValue)) {
        addAccount(accountValue)
      }
    }
  }

  return (
    <AutoColumn gap={'rem'}>
      {!small && (
        <>
          <AutoRow className="flex-col md:flex-row">
            <Wrapper>
              <Input
                className="w-full relative flex-1 md:mr-20 shadow-basic-2"
                placeholder="0x..."
                large
                onChange={(e) => {
                  setAccountValue(e.target.value)
                }}
              />
            </Wrapper>
            {/* <ButtonLight onClick={handleAccountSearch}>Load Account Details</ButtonLight> */}
            <Button onClick={handleAccountSearch} size={Button.sizes.Large} className="w-full md:w-auto mt-16 md:mt-0">
              Load Account Details
            </Button>
          </AutoRow>
        </>
      )}

      {/* <AutoColumn gap={'12px'}> */}
      {/* {!small && (
          <Panel padding="0">
            <div className="bg-basic-1 px-16 rounded py-8">
              <DashGrid center={true} style={{ height: 'fit-content' }}>
                <TYPE.main
                  area="account"
                  fontWeight={700}
                  lineHeight="16px"
                  color="var(--color-basic-7)"
                  fontSize={10}
                  className="uppercase"
                >
                  Saved Accounts
                </TYPE.main>
              </DashGrid>
            </div>
            <Divider />
            {savedAccounts?.length > 0 ? (
              savedAccounts.map((account) => {
                return (
                  <>
                    <DashGrid key={account} center={true} style={{ height: 63, padding: '22px 16px' }}>
                      <Flex
                        area="account"
                        justifyContent="space-between"
                        onClick={() => history.push('/account/' + account)}
                      >
                        <AccountLink>{account?.slice(0, 42)}</AccountLink>
                        <Hover
                          onClick={(e) => {
                            e.stopPropagation()
                            removeAccount(account)
                          }}
                        >
                          <StyledIcon>
                            <X size={16} />
                          </StyledIcon>
                        </Hover>
                      </Flex>
                    </DashGrid>
                    <Divider />
                  </>
                )
              })
            ) : (
              <>
                <DashGrid style={{ height: 63 }} className="flex justify-center flex-col">
                  <TYPE.body style={{ marginLeft: '1rem' }}>No saved accounts</TYPE.body>
                </DashGrid>
                <Divider />
              </>
            )}
          </Panel>
        )} */}

      {/* {small && (
          <>
            <TYPE.main>{'Accounts'}</TYPE.main>
            {savedAccounts?.length > 0 ? (
              savedAccounts.map((account) => {
                return (
                  <RowBetween key={account}>
                    <ButtonFaded onClick={() => history.push('/account/' + account)}>
                      {small ? (
                        <TYPE.header>{account?.slice(0, 6) + '...' + account?.slice(38, 42)}</TYPE.header>
                      ) : (
                        <AccountLink>{account?.slice(0, 42)}</AccountLink>
                      )}
                    </ButtonFaded>
                    <Hover onClick={() => removeAccount(account)}>
                      <StyledIcon>
                        <X size={16} />
                      </StyledIcon>
                    </Hover>
                  </RowBetween>
                )
              })
            ) : (
              <TYPE.light>No pinned wallets</TYPE.light>
            )}
          </>
        )} */}
      {/* </AutoColumn> */}
    </AutoColumn>
  )
}

export default withRouter(AccountSearch)
